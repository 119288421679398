import { STATS_ACTIONS } from "../../constants/actions";

export const fetchStatistics = (token, issuerSelected) => {
  return {
    type: STATS_ACTIONS.STATS_ACTIONS_FECTH_STATS,
    token,
    issuerSelected
  }
}

export const receiveFetchStatistics = (response) => {
  return {
    type: STATS_ACTIONS.STATS_ACTIONS_RECEIVE_FECTH_STATS,
    response
  }
}

export const resetValuesStatistics = (response) => {
  return {
    type: STATS_ACTIONS.STATS_ACTIONS_RESET_VALUES_STATS,
    response
  }
}
