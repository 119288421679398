import { CARD_ACTIONS } from "../../constants/actions";
import { defaultValuesCard } from "../../constants/constants";
import Transform from "../../utils/transform";

let defaultState = defaultValuesCard();

const querycard = (state = defaultState, action) => {
  switch (action.type) {
    case CARD_ACTIONS.FETCH_CARD_REQUEST:
      return {
        ...state,
        isLoadingCard: true,
        responseCardInfo: null,
        errorMessage: '',
        isLoadedGetInfoCard: false
      }
    case CARD_ACTIONS.FETCH_CARD_SUCCESS:
      return {
        ...state,
        isLoadingCard: false,
        responseCardInfo: action.payload,
        errorMessage: '',
        isLoadedGetInfoCard: true
      }
    case CARD_ACTIONS.FETCH_CARD_FAILURE:
      return {
        ...state,
        isLoadingCard: false,
        responseCardInfo: {},
        errorMessage: action.payload,
        isLoadedGetInfoCard: true
      }
    case CARD_ACTIONS.CARD_ACTIONS_LIST_CARDS: {
      let propertiesArray = []
      let newList = [];
      propertiesArray = Object.keys(state.objFiltered)
      propertiesArray.forEach(e => {
        if (state.objFiltered && state.objFiltered[e]) {
          newList.push(e);
        }
      })
      console.log(newList)
      console.log(state.objFiltered)
      return {
        ...state,
        searchingFilters: (newList && newList.length > 1) ? true : false,
        isSearching: true,
        cardsList: null,
        responseCardsList: null,
        changePage: false,
        pageCurrent: action.page
      }
    }
    case CARD_ACTIONS.CARD_ACTIONS_EXPORT_FILE: {
      return {
        ...state,
        exporting: true
      }
    }
    case CARD_ACTIONS.CARD_ACTIONS_RECEIVE_EXPORT_FILE: {
      return {
        ...state,
        exporting: false,
        responseExport: action.response
      }
    }
    case CARD_ACTIONS.CARD_ACTIONS_RESET_EXPORT_FILE: {
      return {
        ...state,
        exporting: false,
        responseExport: null
      }
    }
    case CARD_ACTIONS.CARD_ACTIONS_RECEIVE_LIST_CARDS:
      return {
        ...state,
        responseCardsList: action.response,
        isLoadedCards: true,
        changePage: false,
        cardsList: action.response.data,
        searchingFilters: false,
        isSearching: false
      }
    case CARD_ACTIONS.CARD_ACTIONS_LIST_CARDS_NEXT_PAGE:
      return {
        ...state,
        pageCurrent: state.pageCurrent + 1,
        changePage: true,
        cardsList: null,
        responseCardsList: null
      }
    case CARD_ACTIONS.CARD_ACTIONS_LIST_CARDS_BACK_PAGE:
      return {
        ...state,
        pageCurrent: state.pageCurrent - 1,
        changePage: true,
        cardsList: null,
        responseCardsList: null
      }
    case CARD_ACTIONS.CARD_ACTIONS_LIST_CARDS_GO_TO_PAGE:
      return {
        ...state,
        pageCurrent: action.page,
        changePage: true,
        responseCardsList: null
      }
    case CARD_ACTIONS.CARD_ACTIONS_LIST_CARDS_RESET_FILTERS:
      return {
        ...state,
        objFiltered: {
          brandCard: '',
          numberCard: '',
          maskTypeCard: 'Enmascarada',
          inclearTypeCard: '',
          binCard: '',
          lastPanCard: '',
          fromDateCard: '',
          toDateCard: '',
          enrollChannelCard: '',
          statusCard: ''
        },
        responseCardsList: null,
        cardsList: null
      }
    case CARD_ACTIONS.CARD_ACTIONS_LIST_CARDS_HANDLE_FILTERS: {
      const aux = { ...state.objFiltered };
      console.log(action)
      console.log(aux)

      if ((action.id === 'binCard' && aux.binCard.length === 16) && aux.maskTypeCard === "Enmascarada") {
        const result = Transform.encryptNumber(Number(aux.binCard))
        aux[action.id] = result
      }
      else if (action.id === "maskTypeCard" && aux.binCard.length === 16) {
        console.log("aquio")
        const result = Transform.encryptNumber(Number(aux.binCard))
        aux.binCard = result
      }
      else {
        aux[action.id] = action.value;
      }

      if(action.id === 'maskTypeCard') {
        aux['numberCard'] = "";
      }

      if (action.id === 'inclearTypeCard') {
        aux['binCard'] = "";
        aux['lastPanCard'] = "";
      }

      return {
        ...state,
        objFiltered: aux
      }
    }

    case CARD_ACTIONS.CARD_ACTIONS_LIST_CARDS_DELETE_FILTERS: {
      const aux = { ...state.objFiltered };
      console.log(action)
      console.log(aux)
      aux[action.propertyName] = ''
      return {
        ...state,
        pageCurrent: action.page,
        objFiltered: aux,
        changePage: true,
        responseCardsList: null
      }
    }

    case CARD_ACTIONS.CARD_ACTIONS_ADD_CARD: {
      return {
        ...state,
        addingCard: true,
        addedCard: false,
        responseAddCard: null
      }
    }

    case CARD_ACTIONS.CARD_ACTIONS_RECEIVE_ADD_CARD: {
      return {
        ...state,
        addingCard: false,
        addedCard: true,
        responseAddCard: action.response
      }
    }

    case CARD_ACTIONS.CARD_ACTIONS_UPDATE_CARD: {
      return {
        ...state,
        updatingCard: true,
        updatedCard: false,
        responseUpdateCard: null
      }
    }

    case CARD_ACTIONS.CARD_ACTIONS_RECEIVE_UPDATE_CARD: {
      return {
        ...state,
        updatingCard: false,
        updatedCard: true,
        responseUpdateCard: (action.response) ? action.response : 'error'
      }
    }

    case CARD_ACTIONS.CARD_ACTIONS_RESET_UPDATE_CARD: {
      return {
        ...state,
        updatingCard: false,
        updatedCard: false,
        responseUpdateCard: null,
      }
    }

    case CARD_ACTIONS.CARD_ACTIONS_RESET_VALUES_ADD_CARD: {
      let card = {
        card: "",
        brand_one_letter: "",
        brand_name: "blank",
        authentication_method: "",
        country_code: "",
        phone: "",
        email: ""
      }
      return {
        ...state,
        addingCard: false,
        addedCard: false,
        responseAddCard: null,
        newCard: card
      }
    }

    case CARD_ACTIONS.CARD_ACTIONS_HANDLE_VALUES_ADD_CARD: {
      const aux = { ...state.newCard };
      aux[action.id] = action.value;

      return {
        ...state,
        newCard: aux
      }
    }

    case CARD_ACTIONS.CARD_ACTIONS_CHANGE_ORDER: {
      const aux = { ...state.objFiltered }
      if (action.direction !== 'none') {
        aux.sort = action.direction+"_"+action.field;
      } else {
        aux.sort = '';
      }
      const orderBy = {
        field: action.field,
        direction: action.direction
      }

      return {
        ...state,
        orderBy: orderBy,
        changePage: true,
        responseCardsList: null,
        objFiltered: aux
      }
    }

    default:
      return state
  }
}

export default querycard;