import { AUTHENTICATION_ACTIONS } from "../../constants/actions"

export const getTransaction = (id, token, issuerSelected) => {
  return {
    type: AUTHENTICATION_ACTIONS.AUTHENTICATION_ACTIONS_GET_TRANSACTION,
    id,
    token,
    issuerSelected
  }
}

export const receiveGetTransaction = (response) => {
  return {
    type: AUTHENTICATION_ACTIONS.AUTHENTICATION_ACTIONS_RECEIVE_GET_TRANSACTION,
    response
  }
}

export const getTransactionList = (filters, token, page, issuerSelected) => {
  return {
    type: AUTHENTICATION_ACTIONS.AUTHENTICATION_ACTIONS_LIST_TRANSACTIONS,
    filters,
    token,
    page,
    issuerSelected
  }
}

export const exportAuthentications = (filters, token, issuerSelected) => {
  return {
    type: AUTHENTICATION_ACTIONS.AUTHENTICATION_ACTIONS_EXPORT_FILE,
    filters,
    token,
    issuerSelected
  }
}

export const receiveExportAuthentications = (response) => {
  return {
    type: AUTHENTICATION_ACTIONS.AUTHENTICATION_ACTIONS_RECEIVE_EXPORT_FILE,
    response
  }
}

export const resetExportAuthentications = () => {
  return {
    type: AUTHENTICATION_ACTIONS.AUTHENTICATION_ACTIONS_RESET_EXPORT_FILE,
  }
}

export const receiveGetTransactionList = (response) => {
  return {
    type: AUTHENTICATION_ACTIONS.AUTHENTICATION_ACTIONS_RECEIVE_LIST_TRANSACTIONS,
    response
  }
}

export const nextPage = () => {
  return {
    type: AUTHENTICATION_ACTIONS.AUTHENTICATION_ACTIONS_LIST_TRANSACTIONS_NEXT_PAGE
  }
}

export const backPage = () => {
  return {
    type: AUTHENTICATION_ACTIONS.AUTHENTICATION_ACTIONS_LIST_TRANSACTIONS_BACK_PAGE
  }
}

export const goToPage = (filters, page, token, issuerSelected) => {
  return {
    type: AUTHENTICATION_ACTIONS.AUTHENTICATION_ACTIONS_LIST_TRANSACTIONS_GO_TO_PAGE,
    filters,
    page,
    token,
    issuerSelected
  }
}

export const resetFilters = () => {
  return {
    type: AUTHENTICATION_ACTIONS.AUTHENTICATION_ACTIONS_LIST_TRANSACTIONS_RESET_FILTERS
  }
}

export const resetPage = () => {
  return {
    type: AUTHENTICATION_ACTIONS.AUTHENTICATION_ACTIONS_LIST_TRANSACTIONS_RESET_PAGE
  }
}

export const handleFilters = (id, value) => {
  return {
    type: AUTHENTICATION_ACTIONS.AUTHENTICATION_ACTIONS_LIST_TRANSACTIONS_HANDLE_FILTERS,
    id,
    value
  }
}

export const deleteFilter = (filters, token, page, issuerSelected, filterSelectedToDelete, propertyName) => {
  return {
    type: AUTHENTICATION_ACTIONS.AUTHENTICATION_ACTIONS_LIST_TRANSACTIONS_DELETE_FILTERS,
    filters,
    token,
    page,
    issuerSelected,
    filterSelectedToDelete,
    propertyName
  }
}

export const setTransformedData = (status) => {
  return {
    type: AUTHENTICATION_ACTIONS.AUTHENTICATION_ACTIONS_SET_TRANSFORMED_DATA,
    status
  }
}

export const fetchTransactionsByCard = (card, token, page, viewCard, issuerSelected) => {
  return {
    type: AUTHENTICATION_ACTIONS.AUTHENTICATION_ACTIONS_FECTH_TRANSACTIONS_BY_CARD,
    card,
    token,
    page,
    viewCard,
    issuerSelected
  }
}

export const receiveFetchTransactionsByCard = (response) => {
  return {
    type: AUTHENTICATION_ACTIONS.AUTHENTICATION_ACTIONS_RECEIVE_FECTH_TRANSACTIONS_BY_CARD,
    response
  }
}

export const fetchTransactionsByCardNextPage = () => {
  return {
    type: AUTHENTICATION_ACTIONS.AUTHENTICATION_ACTIONS_FETCH_NEXT_PAGE_TRANSACTIONS_BY_CARD
  }
}

export const fetchTransactionsByCardBackPage = () => {
  return {
    type: AUTHENTICATION_ACTIONS.AUTHENTICATION_ACTIONS_FETCH_BACK_PAGE_TRANSACTIONS_BY_CARD
  }
}

export const fetchTransactionsByCardGoToPage = (page) => {
  return {
    type: AUTHENTICATION_ACTIONS.AUTHENTICATION_ACTIONS_FETCH_GOTO_PAGE_TRANSACTIONS_BY_CARD,
    page: page,
  }
}

export const changeOrder = (field, direction, token, issuerSelected, page, filters) => {
  return {
    type: AUTHENTICATION_ACTIONS.AUTHENTICATION_ACTIONS_CHANGE_ORDER,
    field,
    direction,
    token,
    issuerSelected,
    page,
    filters
  }
}