import { HOME_ACTIONS, MERCHANTS_ACTIONS } from "../../constants/actions";
import { defaultValuesHome } from "../../constants/constants";

let defaultState = defaultValuesHome();

const home = (state = defaultState, action) => {
  switch (action.type) {
    case HOME_ACTIONS.HOME_ACTIONS_UPDATE_QUERIES:
      return {
        ...state,
        listQueries: action.list,
        isLoaded: true
      }
    case HOME_ACTIONS.HOME_ACTIONS_LIST_BRANDS_BY_ACQUIRER:
      return {
        ...state,
      }
    case HOME_ACTIONS.HOME_ACTIONS_RECEIVE_RESPONSE_LIST_BRANDS_BY_ACQUIRER: {
      let newArrayCurrencies = []
      let aux = { ...action.response }
      aux.currencies.forEach((curren, index) => {
        newArrayCurrencies.push({ iso_code: curren.iso_code, numeric_code: curren.numeric_code, byBrand: aux.brands[index].code, description: curren.description })
      })
      console.log(newArrayCurrencies)
      return {
        ...state,
        isLoadedBrands: true,
        responseListBrands: action.response,
        brands: action.response.brands,
        currencies: newArrayCurrencies,
        currenciesByAcquirer: action.response.CurrenciesByAcquirer,
        protocols: action.response.protocols
      }
    }
    case HOME_ACTIONS.HOME_ACTIONS_LIST_COUNTRIES_BY_ACQUIRER:
      return {
        ...state
      }
    case HOME_ACTIONS.HOME_ACTIONS_RECEIVE_RESPONSE_LIST_COUNTRIES: {
      console.log(action.response)
      return {
        ...state,
        countries: action.response.paises,
        isLoadedCountries: true
      }
    }
    case MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_SENT_FILTERS_LIST_MERCHANTS:
      return {
        ...state,
      }
    case MERCHANTS_ACTIONS.HOME_ACTIONS_LIST_ACQUIRERS_LOADING:
      return {
        ...state,
        listIssuersIsLoading: true
      }
    case MERCHANTS_ACTIONS.HOME_ACTIONS_LIST_ACQUIRERS_LOADED:
      return {
        ...state,
        listIssuersIsLoaded: true,
        listIssuersIsLoading: false
      }
    case HOME_ACTIONS.HOME_ACTIONS_RESET_BRANDS:
      return {
        ...state,
        brands: []
      }
    case HOME_ACTIONS.HOME_ACTIONS_LIST_ACQUIRERS_LOADING:
      return {
        ...state,
        issuerSelected: action.item
      }
    case HOME_ACTIONS.HOME_ACTIONS_GET_USER_ACTIVE:
      return {
        ...state,
        userActiveIsLoaded: false,
        userActive: null
      }
    case HOME_ACTIONS.HOME_ACTIONS_RECEIVE_RESPONSE_GET_USER_ACTIVE:
      return {
        ...state,
        userActive: action.response,
        userActiveIsLoaded: true
      }
    case HOME_ACTIONS.HOME_ACTIONS_HANDLE_PICTURE_ACQUIRER:
      return {
        ...state,
        changingIssuerPicture: true
      }
    case HOME_ACTIONS.HOME_ACTIONS_RECEIVE_RESPONSE_HANDLE_PICTURE_ACQUIRER:
      return {
        ...state,
        responseIssuerImageUpdated: action.response,
        changeImageIsCompleted: true
      }
    default:
      return state
  }
}

export default home;
