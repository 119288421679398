import { defaultValuesMerchants } from "../../constants/constants";

let defaultState = defaultValuesMerchants();

const merchants = (state = defaultState, action) => {
  switch (action.type) {
    default:
      return state
  }
}

export default merchants;