import { OPERATION_ACTIONS } from "../../constants/actions";
import { defaultValuesOperation } from "../../constants/constants";

let defaultState = defaultValuesOperation();

const queryoperation = (state = defaultState, action) => {
  switch (action.type) {
    case OPERATION_ACTIONS.OPERATION_ACTIONS_GET_OPERATION:
      return {
        ...state
      }
    case OPERATION_ACTIONS.OPERATION_ACTIONS_RECEIVE_GET_OPERATION:
      return {
        ...state,
        responseOperationInfo: action.response,
        isLoadedGetInfoOperation: true,
      }
    case OPERATION_ACTIONS.OPERATION_ACTIONS_LIST_OPERATIONS: {
      let propertiesArray = []
      let newList = [];
      propertiesArray = Object.keys(state.objFiltered)
      propertiesArray.forEach(e => {
        if (state.objFiltered && state.objFiltered[e]) {
          newList.push(e);
        }
      })
      return {
        ...state,
        searchingFilters: (newList && newList.length > 1) ? true : false,
        isSearching: true,
        operationsList: null,
        responseOperationsList: null,
        changePage: false,
        pageCurrent: action.page
      }
    }
    case OPERATION_ACTIONS.OPERATION_ACTIONS_EXPORT_FILE: {
      return {
        ...state,
        exporting: true
      }
    }
    case OPERATION_ACTIONS.OPERATION_ACTIONS_RECEIVE_EXPORT_FILE: {
      return {
        ...state,
        exporting: false,
        responseExport: action.response
      }
    }
    case OPERATION_ACTIONS.OPERATION_ACTIONS_RESET_EXPORT_FILE: {
      return {
        ...state,
        exporting: false,
        responseExport: null
      }
    }
    case OPERATION_ACTIONS.OPERATION_ACTIONS_RECEIVE_LIST_OPERATIONS:
      return {
        ...state,
        responseOperationsList: action.response,
        isLoadedOperations: true,
        changePage: false,
        operationsList: action.response.data,
        searchingFilters: false,
        isSearching: false
      }
    case OPERATION_ACTIONS.OPERATION_ACTIONS_LIST_OPERATIONS_NEXT_PAGE:
      return {
        ...state,
        pageCurrent: state.pageCurrent + 1,
        changePage: true,
        operationsList: null,
        responseOperationsList: null
      }
    case OPERATION_ACTIONS.OPERATION_ACTIONS_LIST_OPERATIONS_BACK_PAGE:
      return {
        ...state,
        pageCurrent: state.pageCurrent - 1,
        changePage: true,
        operationsList: null,
        responseOperationsList: null
      }
    case OPERATION_ACTIONS.OPERATION_ACTIONS_RESET_TO_FIRST:
      return {
        ...state,
        pageCurrent: 1
      }
    case OPERATION_ACTIONS.OPERATION_ACTIONS_LIST_OPERATIONS_GO_TO_PAGE:
      return {
        ...state,
        pageCurrent: action.page,
        changePage: true,
        responseOperationsList: null
      }
    case OPERATION_ACTIONS.OPERATION_ACTIONS_FETCH_NEXT_PAGE_OPERATIONS_BY_CARD:
      return {
        ...state,
        pageCurrentOperations: state.pageCurrentOperations + 1,
        responseOperationsByCard: null,
        isLoadingOperationsByCard: false,
      }
    case OPERATION_ACTIONS.OPERATION_ACTIONS_FETCH_BACK_PAGE_OPERATIONS_BY_CARD:
      return {
        ...state,
        pageCurrentOperations: state.pageCurrentOperations - 1,
        responseOperationsByCard: null,
        isLoadingOperationsByCard: false
      }
    case OPERATION_ACTIONS.OPERATION_ACTIONS_FETCH_GOTO_PAGE_OPERATIONS_BY_CARD:
      return {
        ...state,
        pageCurrentOperations: action.page,
        responseOperationsByCard: null,
        isLoadingOperationsByCard: false
      }
    case OPERATION_ACTIONS.OPERATION_ACTIONS_LIST_OPERATIONS_RESET_FILTERS:
      return {
        ...state,
        objFiltered: {
          fromDateOperation: '',
          toDateOperation: '',
          brandOperation: '',
          cardNumberOperation: '',
          typeOperation: '',
          cardCurrentStateOperation: ''
        },
        responseOperationsList: null,
        operationsList: null
      }
    case OPERATION_ACTIONS.OPERATION_ACTIONS_LIST_OPERATIONS_HANDLE_FILTERS: {
      const aux = { ...state.objFiltered };
      aux[action.id] = action.value;
      if(action.id === 'maskTypeOperation') {
        aux['cardNumberOperation'] = "";
      }

      if (action.id === 'inclearTypeOperation') {
        aux['binOperation'] = "";
        aux['lastPanOperation'] = "";
      }
      return {
        ...state,
        objFiltered: aux
      }
    }

    case OPERATION_ACTIONS.OPERATION_ACTIONS_LIST_OPERATIONS_DELETE_FILTERS: {
      const aux = { ...state.objFiltered };
      aux[action.propertyName] = ''
      return {
        ...state,
        pageCurrent: action.page,
        objFiltered: aux,
        changePage: true,
        responseOperationsList: null
      }
    }

    case OPERATION_ACTIONS.OPERATION_ACTIONS_FETCH_OPERATIONS_BY_CARD: {
      return {
        ...state,
        detailCardNumber: action.card,
        responseOperationsByCard: null,
        isLoadingOperationsByCard: true,
        pageCurrentOperations: action.page
      }
    }

    case OPERATION_ACTIONS.OPERATION_ACTIONS_RECEIVE_FETCH_OPERATIONS_BY_CARD: {
      return {
        ...state,
        responseOperationsByCard: action.response,
        isLoadingOperationsByCard: false,
        tabListOperationsByCard: action.response.data
      }
    }

    case OPERATION_ACTIONS.OPERATION_ACTIONS_CHANGE_ORDER: {
      const aux = { ...state.objFiltered }
      if (action.direction !== 'none') {
        aux.sort = action.direction+"_"+action.field;
      } else {
        aux.sort = '';
      }
      const orderBy = {
        field: action.field,
        direction: action.direction
      }

      return {
        ...state,
        orderBy: orderBy,
        changePage: true,
        responseOperationsList: null,
        objFiltered: aux
      }
    }

    default:
      return state
  }
}

export default queryoperation;