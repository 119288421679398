import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React           from 'react';
import ReactDOM        from 'react-dom';
import { Provider }    from 'react-redux';

import App   from './App';
import store from './store';

import * as serviceWorker from './serviceWorker';

import './index.css';
import 'antd/dist/antd.css';

import GA4React from 'ga-4-react';

import './i18n';

const GACODE = `${process.env.REACT_APP_GA4ANALYTICS}`
const ga4react = new GA4React(GACODE, {debug_mode: true, send_page_view: true});
ga4react.initialize();

export const GA4pageview = (page: string) => {
  try {
    document.title = "Alignet | " + page;
  } catch (error) {
    console.error(error);
  }
}

ReactDOM.render(
    <Provider store={store}>
        <App/>
    </Provider>,
    document.getElementById('root')
);

serviceWorker.unregister();
