import { isConstructorDeclaration } from "typescript";
import Helpers from "../utils/helpers";

// TRANSACTIONS
export const GET_LIST_TRANSACTIONS = (filters, page, filterSelectedToDelete, isExport = false) => {
  // console.log(filterSelectedToDelete)
  console.log(filters)
  let result = `${process.env.REACT_APP_API_AUTHENTICATION}/list?page=${page}&size=10`;
  if (filterSelectedToDelete) {
    let lastUrl = localStorage.getItem("urlQueries");
    // console.log(filters)
    // console.log(filterSelectedToDelete)
    // console.log(result)
    // eslint-disable-next-line default-case
    // console.log(lastUrl)
    if (filterSelectedToDelete === 'Fecha de inicio') {
      let format = filters.fromDateAuthentication.split("/")
      format = format.reverse();
      format = format.join("-")
      lastUrl = lastUrl.replace(`&date_start=${format}`, '')
    }
    if (filterSelectedToDelete === 'Fecha de fin') {
      let format = filters.toDateAuthentication.split("/")
      format = format.reverse();
      format = format.join("-")
      lastUrl = lastUrl.replace(`&date_end=${format}`, '')
    }
    if (filterSelectedToDelete === 'Resultado de autenticación') {
      console.log("RESULTADO DE AUTENTICACION-----------------------")
      console.log("authResultAuthentication",filters.authResultAuthentication)
      filters.authResultAuthentication = filters.authResultAuthentication.toLowerCase()
      filters.authResultAuthentication = filters.authResultAuthentication.replace(" ", "_").replace(" ", "_").replace(" ", "_")
      console.log("authResultAuthentication",filters.authResultAuthentication)
      filters.authResultAuthentication = filters.authResultAuthentication.replace("ó", "o")
      filters.authResultAuthentication = filters.authResultAuthentication.replace("á", "a")
      // filters.authResultAuthentication = filters.authResultAuthentication.replace("ó", "o")
      lastUrl = lastUrl.replace(`&authentication=${filters.authResultAuthentication}`, '')
      console.log("-------LAST URL----------------: ",lastUrl)
    }

    if (filterSelectedToDelete === 'Tipo de autenticación') {
      console.log("TIPO DE AUTENTICACION-----------------------")
      console.log("-------------SELECT FILTER TIPO DE AUTH------------")
      console.log("authTypeAuthentication",filters.authTypeAuthentication)
      filters.authTypeAuthentication = filters.authTypeAuthentication.toLowerCase()
      filters.authTypeAuthentication = filters.authTypeAuthentication.replace(" ", "_").replace(" ", "_").replace(" ", "_")
      console.log("authTypeAuthentication",filters.authTypeAuthentication)
      filters.authTypeAuthentication = filters.authTypeAuthentication.replace("ó", "o")
      filters.authTypeAuthentication = filters.authTypeAuthentication.replace("á", "a")
      filters.authTypeAuthentication = filters.authTypeAuthentication.replace("í", "i")
      lastUrl = lastUrl.replace(`&authenticationType=${filters.authTypeAuthentication}`, '')
      console.log("-------LAST URL----------------: ",lastUrl)
    }

    if (filterSelectedToDelete === 'Nombre de comercio') {
      lastUrl = lastUrl.replace(`&commerce_name=${filters.nameAuthentication}`, '')
    }
    if (filterSelectedToDelete === 'Código de comercio') {
      lastUrl = lastUrl.replace(`&commerce=${filters.codeAuthentication}`, '')
    }
    if (filterSelectedToDelete === 'Número de pedido') {
      lastUrl = lastUrl.replace(`&number_orde=${filters.numberOrderAuthentication}`, '')
    }
    if (filterSelectedToDelete === 'Marca') {
      lastUrl = lastUrl.replace(`&brand=${filters.brandAuthentication}`, '')
    }
    if (filterSelectedToDelete === 'Número de tarjeta') {
      lastUrl = lastUrl.replace(`&threeDSServerRefNumber=${Helpers.withSHA512(filters.cardNumberAuthentication)}`, '')
    }
    if (filterSelectedToDelete === 'Enmascarado') {
      lastUrl = lastUrl.replace(`&pan_search_type=${filters.maskTypeAuthentication}`, '')
    }
    if (filterSelectedToDelete === 'En claro') {
      lastUrl = lastUrl.replace(`&pan_search_type=${filters.inclearTypeAuthentication}`, '')
    }
    if (filterSelectedToDelete === 'BIN') {
      lastUrl = lastUrl.replace(`&bin=${filters.binAuthentication}`, '')
    }
    if (filterSelectedToDelete === 'LAST PAN') {
      lastUrl = lastUrl.replace(`&last_pan=${filters.lastPanAuthentication}`, '')
    }
    if (filterSelectedToDelete === 'Moneda') {
      lastUrl = lastUrl.replace(`&purchaseCurrency=${filters.currencyAuthentication}`, '')
    }
    if (filterSelectedToDelete === 'ECI') {
      lastUrl = lastUrl.replace(`&eci=${filters.eciAuthentication}`, '')
    }
    if (filterSelectedToDelete === 'Canal de compra') {
      lastUrl = lastUrl.replace(`&channel=${filters.channelAuthentication}`, '')
    }
    if (filterSelectedToDelete === 'Estado de Tarjeta') {
      lastUrl = lastUrl.replace(`&statusCard=${filters.statusCardAuthentication}`, '')
    }
    if (filterSelectedToDelete === 'Monto Min') {
      lastUrl = lastUrl.replace(`&amountMin=${filters.amountMinAuthentication}`, '')
    }
    if (filterSelectedToDelete === 'Monto Max') {
      lastUrl = lastUrl.replace(`&amountMax=${filters.amountMaxAuthentication}`, '')
    }
    if (filterSelectedToDelete === 'Versión') {
      console.log("VERSION")
      filters.authValueVersion = filters.authValueVersion.toLowerCase()
      filters.authValueVersion = filters.authValueVersion.replace("ó", "o")
      lastUrl = lastUrl.replace(`&messageVersion=${filters.authValueVersion}`, '')
    }
    if (filterSelectedToDelete === '3DS Server Transaction ID') {
      lastUrl = lastUrl.replace(`&threeDSServerTransID=${filters.threeDSServerTransID}`, '')
    }
    if (filterSelectedToDelete === 'DS Transaction ID') {
      lastUrl = lastUrl.replace(`&acsTransID=${filters.acsTransID}`, '')
    }
    if (filterSelectedToDelete === 'ACS Transaction ID') {
      lastUrl = lastUrl.replace(`&dsTransID=${filters.dsTransID}`, '')
    }

    // console.log(lastUrl)
    localStorage.setItem("urlQueries", lastUrl)
    return lastUrl;
  }
  else {
    // console.log(filters)
    if (filters) {
      // console.log(filters)
      // console.log(result, "ReSULT")
      // if (result.search("&pan_search_type=") !== -1) {
      //   console.log("existe &pan_search_type=")
      //   result.replace("&pan_search_type=", "")
      // }
      if (filters.fromDateAuthentication) result = result + '&date_start=' + filters.fromDateAuthentication
      if (filters.toDateAuthentication) result = result + '&date_end=' + filters.toDateAuthentication
      if (filters.authResultAuthentication && filters.authResultAuthentication !== "Todos") {
        filters.authResultAuthentication = filters.authResultAuthentication.replace("ó", "o")
        filters.authResultAuthentication = filters.authResultAuthentication.replace("á", "a")
        console.log("AuthELSE",filters.authResultAuthentication)
        result = result + '&authentication=' + filters.authResultAuthentication.replace(" ", "_").replace(" ", "_").replace(" ", "_").toLowerCase()
      }
      if (filters.authTypeAuthentication && filters.authTypeAuthentication !== "Todos") {
        console.log("Filter auth type")
        filters.authTypeAuthentication = filters.authTypeAuthentication.replace("ó", "o")
        filters.authTypeAuthentication = filters.authTypeAuthentication.replace("á", "a")
        filters.authTypeAuthentication = filters.authTypeAuthentication.replace("í", "i")
        console.log("AuthTypeElse",filters.authTypeAuthentication)
        result = result + '&authenticationType=' + filters.authTypeAuthentication.replace(" ", "_").replace(" ", "_").replace(" ", "_").toLowerCase().replace("ó", "o")
        console.log("RESULT LINK",result)
      }

      if (filters.authValueVersion) result = result + '&messageVersion=' + filters.authValueVersion
      if (filters.nameAuthentication) result = result + '&commerce_name=' + filters.nameAuthentication
      if (filters.codeAuthentication) result = result + '&commerce=' + filters.codeAuthentication
      if (filters.numberOrderAuthentication) result = result + '&number_orde=' + filters.numberOrderAuthentication
      if (filters.brandAuthentication) result = result + '&brand=' + filters.brandAuthentication
      if (filters.cardNumberAuthentication) result = result + '&threeDSServerRefNumber=' + Helpers.withSHA512(filters.cardNumberAuthentication)
      if (filters.binAuthentication) result = result + '&bin=' + filters.binAuthentication
      if (filters.currencyAuthentication && filters.currencyAuthentication !== "Todos") result = result + '&purchaseCurrency=' + filters.currencyAuthentication
      if (filters.eciAuthentication) result = result + '&eci=' + filters.eciAuthentication
      if (filters.lastPanAuthentication) result = result + '&last_pan=' + filters.lastPanAuthentication
      if (filters.inclearTypeAuthentication) {
        if (filters.maskTypeAuthentication) filters.maskTypeAuthentication = ""
        result = result + '&pan_search_type=' + filters.inclearTypeAuthentication
      }
      if (filters.maskTypeAuthentication) {
        if (filters.inclearTypeAuthentication) filters.inclearTypeAuthentication = ""
        result = result + '&pan_search_type=' + filters.maskTypeAuthentication
      }
      if (filters.channelAuthentication) result = result + '&channel=' + filters.channelAuthentication
      if (filters.statusCardAuthentication) result = result + '&statusCard=' + filters.statusCardAuthentication
      if (filters.amountMinAuthentication) result = result + '&amountMin=' + filters.amountMinAuthentication
      if (filters.amountMaxAuthentication) result = result + '&amountMax=' + filters.amountMaxAuthentication
      if (filters.threeDSServerTransID) result = result + '&threeDSServerTransID=' + filters.threeDSServerTransID
      if (filters.acsTransID) result = result + '&acsTransID=' + filters.acsTransID
      if (filters.dsTransID) result = result + '&dsTransID=' + filters.dsTransID
      if (filters.sort && !filters.sort.includes('none_')) {
        result = result + '&sort=' + filters.sort
      }
    }
    // console.log(result)
    if (result.indexOf("?&") !== -1) {
      result = result.replace("?&", "?")
    }
  }

  if (isExport) {
    return Helpers.buildExportURL(result, 'authentications');
  }

  localStorage.setItem("urlQueries", result)
  console.log("aqui")
  return result
}
// OPERATIONS
export const GET_LIST_OPERATIONS = (filters, page, filterSelectedToDelete, isExport = false) => {
  console.log(filters)
  let result = `${process.env.REACT_APP_API_OPERATION}/list?page=${page}&size=10`;
  if (filterSelectedToDelete) {
    let lastUrl = localStorage.getItem("urlOperations");

    if (filterSelectedToDelete === 'Fecha de inicio') {
      let format = filters.fromDateOperation.split("/")
      format = format.reverse();
      format = format.join("-")
      lastUrl = lastUrl.replace(`&date_start=${format}`, '')
    }
    if (filterSelectedToDelete === 'Fecha de fin') {
      let format = filters.toDateOperation.split("/")
      format = format.reverse();
      format = format.join("-")
      lastUrl = lastUrl.replace(`&date_end=${format}`, '')
    }
    if (filterSelectedToDelete === 'Marca') {
      lastUrl = lastUrl.replace(`&brand=${filters.brandOperation}`, '')
    }
    if (filterSelectedToDelete === 'Enmascarado') {
      lastUrl = lastUrl.replace(`&pan_search_type=${filters.maskTypeOperation}`, '')
    }
    if (filterSelectedToDelete === 'En claro') {
      lastUrl = lastUrl.replace(`&pan_search_type=${filters.inclearTypeOperation}`, '')
    }
    if (filterSelectedToDelete === 'BIN') {
      lastUrl = lastUrl.replace(`&bin=${filters.binOperation}`, '')
    }
    if (filterSelectedToDelete === 'LAST PAN') {
      lastUrl = lastUrl.replace(`&last_pan=${filters.lastPanOperation}`, '')
    }
    if (filterSelectedToDelete === 'Número de tarjeta') {
      lastUrl = lastUrl.replace(`&card_sha=${Helpers.withSHA512(filters.cardNumberOperation)}`, '')
    }
    if (filterSelectedToDelete === 'Estado de Tarjeta') {
      lastUrl = lastUrl.replace(`&card_status_after_op=${filters.cardCurrentStateOperation}`, '')
    }
    if (filterSelectedToDelete === 'Tipo de Operación') {
      lastUrl = lastUrl.replace(`&operation_type=${filters.typeOperation}`, '')
    }

    localStorage.setItem("urlOperations", lastUrl);
    return lastUrl;
  }
  else {
    if (filters) {
      if (filters.fromDateOperation) result = result + '&date_start=' + filters.fromDateOperation
      if (filters.toDateOperation) result = result + '&date_end=' + filters.toDateOperation
      if (filters.brandOperation) result = result + '&brand=' + filters.brandOperation
      if (filters.cardNumberOperation) result = result + '&card_sha=' + Helpers.withSHA512(filters.cardNumberOperation)
      if (filters.binOperation) result = result + '&bin=' + filters.binOperation
      if (filters.eciOperation) result = result + '&eci=' + filters.eciOperation
      if (filters.lastPanOperation) result = result + '&last_pan=' + filters.lastPanOperation
      if (filters.inclearTypeOperation) {
        if (filters.maskTypeOperation) filters.maskTypeOperation = ""
        result = result + '&pan_search_type=' + filters.inclearTypeOperation
      }
      if (filters.maskTypeOperation) {
        if (filters.inclearTypeOperation) filters.inclearTypeOperation = ""
        result = result + '&pan_search_type=' + filters.maskTypeOperation
      }
      if (filters.cardCurrentStateOperation) result = result + '&card_status_after_op=' + filters.cardCurrentStateOperation
      if (filters.typeOperation) result = result + '&operation_type=' + filters.typeOperation
      if (filters.sort && !filters.sort.includes('none_')) {
        result = result + '&sort=' + filters.sort
      }
    }
    if (result.indexOf("?&") !== -1) {
      result = result.replace("?&", "?")
    }
  }

  if (isExport) {
    return Helpers.buildExportURL(result, 'operations');
  }

  localStorage.setItem("urlOperations", result);
  return result;
}
// CARDS
export const GET_LIST_CARDS = (filters, page, filterSelectedToDelete, isExport = false) => {
  console.log(filterSelectedToDelete);
  console.log(filters);
  let result = `${process.env.REACT_APP_API_CARD}/list?page=${page}&size=10`;
  if (filterSelectedToDelete) {
    let lastUrl = localStorage.getItem("urlCards");

    if (filterSelectedToDelete === 'Fecha de inicio') {
      let format = filters.fromDateCard.split("/")
      format = format.reverse();
      format = format.join("-")
      lastUrl = lastUrl.replace(`&date_start=${format}`, '')
    }
    if (filterSelectedToDelete === 'Fecha de fin') {
      let format = filters.toDateCard.split("/")
      format = format.reverse();
      format = format.join("-")
      lastUrl = lastUrl.replace(`&date_end=${format}`, '')
    }
    if (filterSelectedToDelete === 'Marca') {
      lastUrl = lastUrl.replace(`&brand=${filters.brandCard}`, '')
    }
    if (filterSelectedToDelete === 'Enmascarado') {
      lastUrl = lastUrl.replace(`&pan_search_type=${filters.maskTypeCard}`, '')
    }
    if (filterSelectedToDelete === 'En claro') {
      lastUrl = lastUrl.replace(`&pan_search_type=${filters.inclearTypeCard}`, '')
    }
    if (filterSelectedToDelete === 'BIN') {
      lastUrl = lastUrl.replace(`&bin=${filters.binCard}`, '')
    }
    if (filterSelectedToDelete === 'LAST PAN') {
      lastUrl = lastUrl.replace(`&last_pan=${filters.lastPanCard}`, '')
    }
    if (filterSelectedToDelete === 'Canal de enrolamiento') {
      lastUrl = lastUrl.replace(`&channelEnroll=${filters.enrollChannelCard}`, '')
    }
    if (filterSelectedToDelete === 'Estado de la tarjeta') {
      lastUrl = lastUrl.replace(`&statusCard=${filters.statusCard}`, '')
    }
    if (filterSelectedToDelete === 'Número de tarjeta') {
      lastUrl = lastUrl.replace(`&cardNumber=${Helpers.withSHA512(filters.numberCard)}`, '')
    }
    localStorage.setItem("urlCards", lastUrl)
    return lastUrl;
  }
  else {
    if (filters) {
      if (filters.fromDateCard) result = result + '&date_start=' + filters.fromDateCard;
      if (filters.toDateCard) result = result + '&date_end=' + filters.toDateCard;
      if (filters.brandCard) result = result + '&brand=' + filters.brandCard;
      if (filters.numberCard) result = result + '&cardNumber=' + Helpers.withSHA512(filters.numberCard);
      if (filters.binCard) result = result + '&bin=' + filters.binCard
      if (filters.lastPanCard) result = result + '&last_pan=' + filters.lastPanCard
      if (filters.inclearTypeCard) {
        if (filters.maskTypeCard) filters.maskTypeCard = ""
        result = result + '&pan_search_type=' + filters.inclearTypeCard
      }
      if (filters.maskTypeCard) {
        if (filters.inclearTypeCard) filters.inclearTypeCard = ""
        result = result + '&pan_search_type=' + filters.maskTypeCard
      }
      if (filters.enrollChannelCard) result = result + '&channelEnroll=' + filters.enrollChannelCard
      if (filters.statusCard) result = result + '&statusCard=' + filters.statusCard
      if (filters.sort && !filters.sort.includes('none_')) {
        result = result + '&sort=' + filters.sort
      }
    }
    // console.log(result)
    if (result.indexOf("?&") !== -1) {
      result = result.replace("?&", "?")
    }
  }

  if (isExport) {
    return Helpers.buildExportURL(result, 'cards');
  }

  localStorage.setItem("urlCards", result)
  console.log("aqui")
  return result
}
// TRANSACTIONS BY CARD
export const GET_LIST_TRANSACTIONS_BY_CARD = (card, page, viewCard) => {
  let result = `${process.env.REACT_APP_API_AUTHENTICATION}/list?page=${page}&size=10&threeDSServerRefNumber=${card}&pan_search_type=EnClaro&view_card=${viewCard}`;
  return result;
}
// OPERATIONS BY CARD
export const GET_LIST_OPERATIONS_BY_CARD = (card, page) => {
  let result = `${process.env.REACT_APP_API_OPERATION}/list?page=${page}&size=10&pan_search_type=EnClaro&card_sha=${card}`;
  return result;
}
// WHITELIST BY CARD
export const GET_LIST_WHITELIST_BY_CARD = (card, page) => {
  let result = `${process.env.REACT_APP_API_WHITELIST}?page=${page}&size=10&pan_search_type=EnClaro&card_sha=${card}`;
  return result;
}
// CONFIGURATION
export const GET_LIST_BRANDS = (id) => {
  let result = `${process.env.REACT_APP_API_ISSUER}/${id}`;
  return result;
}
// COUNTRIES
export const GET_LIST_COUNTRIES = () => {
  return "https://n9eqdh0t0b.execute-api.us-east-1.amazonaws.com/default/configuration";
}
// USERS
export const GET_LIST_USERS = (filters, pageCurrentDashboard) => {
  let result = `${process.env.REACT_APP_API_USER}/list?page=${pageCurrentDashboard}&size=10`;
  if (filters) {
    if (filters.fromDateUser) {
      result = result + '&date_start=' + filters.fromDateUser
    }
    if (filters.toDateUser) {
      result = result + '&date_end=' + filters.toDateUser
    }
    if (filters.name) {
      filters.state = filters.state.replace(' ', '_')
      filters.name = filters.name.replace("ó", "o")
      filters.name = filters.name.replace("á", "a")
      filters.name = filters.name.replace("í", "i")
      result = result + '&name=' + filters.name
    }
    if (filters.lastName) result = result + '&lastname=' + filters.lastName
    if (filters.email) result = result + '&email=' + filters.email
    if (filters.role) result = result + '&role=' + filters.role.toLowerCase()
    if (filters.state) {
      filters.state = filters.state.replace(' ', '_')
      filters.state = filters.state.replace("ó", "o")
      filters.state = filters.state.replace("á", "a")
      filters.state = filters.state.replace("í", "i")
      result = result + '&status=' + filters.state.toLowerCase()
    }
    if (filters.sort && !filters.sort.includes('none_')) {
      result = result + '&sort=' + filters.sort
    }
  }
  localStorage.setItem("urlUsers", result)
  return result;
}
// DETALLE DE ASOCIACION
export const GET_ASSOCIATE_DETAIL = (id, pageCurrent) => {
  let result = `${process.env.REACT_APP_API_ASSOCIATION_DETAIL}/${id}?page=${pageCurrent}&size=10`;
  return result;
}
// STATISTICS
export const GET_LIST_STATISTICS = () => {
  const issuer_id = localStorage.getItem('issuer_id');
  let result = `${process.env.REACT_APP_API_QUERY}?operation=select&table=statistics_authentications&filters=issuer_id:${issuer_id}&fields=trans_status,quantity,year,month`;
  return result;
} 