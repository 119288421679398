import { GET_LIST_BRANDS, GET_LIST_COUNTRIES, GET_LIST_CARDS, GET_LIST_TRANSACTIONS, GET_LIST_USERS, GET_ASSOCIATE_DETAIL, GET_LIST_OPERATIONS, GET_LIST_TRANSACTIONS_BY_CARD, GET_LIST_OPERATIONS_BY_CARD, GET_LIST_STATISTICS,GET_LIST_WHITELIST_BY_CARD } from '../constants/routes';
import { getI18n } from "react-i18next";
// AUTH

const withLanguageParameter = (url) => {
  const languageLocal = localStorage.getItem('language') || getI18n().language;
  let language = languageLocal;
  
  if (languageLocal === 'es') {
    language = `spanish`;
  }
  if (languageLocal === 'en') {
    language = `english`;
  }

  if (url.indexOf('?') === -1) {
    return `${url}?language=${language}`;
  } else {
    return `${url}&language=${language}`;
  }

}

export const getAuthorizationUser = async (user) => {
  try {
    console.log(new Date().getTime() / 1000);
    const options = { method: 'POST', body: JSON.stringify(user), mode: 'cors' };
    console.log(new Date().getTime() / 1000);
    const response = await fetch(withLanguageParameter(process.env.REACT_APP_API_AUTH), options);
    console.log(new Date().getTime() / 1000);
    const data = response.json();

    return data;
  }
  catch (e) {
    console.log(new Date().getTime() / 1000);
    console.log('error:', e)
  }
}

export const getAuthorizationByCode = async (token) => {
  try {
    let issuerSelected = localStorage.getItem('issuer-key');
    issuerSelected = (issuerSelected) ? issuerSelected : '_';
    const options = {
      method: "GET",
      headers: {
        'Authorization': `${token}`,
        'Content-type': 'application/json',
        'issuer-Key': issuerSelected,
      },
      mode: 'cors'
    }

    const response = await fetch(withLanguageParameter(process.env.REACT_APP_API_AUTH), options);
    const data = response.json();
    return data;
  }
  catch (e) {
    console.log('error:', e)
  }
}

export const putAuthorizationByCode = async (token, code) => {
  try {
    const body = {
      "action": "validate_auth",
      "validation_code": code
    }
    // console.log(token)
    const options = {
      method: "PUT",
      headers: {
        'Authorization': `${token}`,
        'Content-type': 'application/json'
      },
      body: JSON.stringify(body),
      mode: 'cors',
    }
    console.log(options)
    console.log(JSON.stringify(body))
    const response = await fetch(withLanguageParameter(process.env.REACT_APP_API_AUTH), options);
    const data = response.json();
    console.log('PUT AUTHORIZATION');
    return data;
  }
  catch (e) {
    console.log('error:', e)
  }
}

export const postRecoveryPasswordUser = async (email) => {
  const body = {
    "action": "str",
    "email": email
  }
  try {
    const options = { method: 'POST', body: JSON.stringify(body), mode: 'cors' };
    const response = await fetch(withLanguageParameter(process.env.REACT_APP_API_RECOVERY), options)
    const data = response.json();
    console.log(data)
    return data;
  }
  catch (e) {
    console.log('error:', e)
  }
}

export const getListBrands = async (id, token) => {
  try {
    const options = {
      method: 'GET',
      headers: {
        'Authorization': token,
        'Content-type': 'application/json',
      },
      // body: JSON.stringify(body),
      mode: 'cors'
    };
    const response = await fetch(withLanguageParameter(GET_LIST_BRANDS(id)), options)
    const data = response.json();

    return data;
  }
  catch (e) {
    console.log('error:', e)
  }
}

export const getListCountries = async () => {
  try {
    const options = {
      method: 'GET',
      headers: {
        'Authorization': 'A2WkQmwaXrPE7eFyHf4R74HS',
        'Content-type': 'application/json',
      },
      // body: JSON.stringify(body),
      mode: 'cors'
    };
    const response = await fetch(withLanguageParameter(GET_LIST_COUNTRIES()), options)
    const data = response.json();

    return data;
  }
  catch (e) {
    console.log('error:', e)
  }
}

export const getListAllTransactionsFiltereds = async (filters, token, page, issuerSelected, filterSelectedToDelete) => {
  try {
    const options = {
      method: 'GET',
      headers: {
        'Authorization': token,
        'issuer-Key': issuerSelected,
        'Content-type': 'application/json',
      },
      mode: 'cors'
    };
    const response = await fetch(withLanguageParameter(GET_LIST_TRANSACTIONS(filters, page, filterSelectedToDelete)), options)
    const data = response.json();

    return data;
  }
  catch (e) {
    console.log('error:', e)
  }
}

export const getListAllOperationsFiltereds = async (filters, token, page, issuerSelected, filterSelectedToDelete) => {
  try {
    const options = {
      method: 'GET',
      headers: {
        'Authorization': token,
        'issuer-Key': issuerSelected,
        'Content-type': 'application/json',
      },
      mode: 'cors'
    };
    const response = await fetch(withLanguageParameter(GET_LIST_OPERATIONS(filters, page, filterSelectedToDelete)), options)
    const data = response.json();

    return data;
  }
  catch (e) {
    console.log('error:', e)
  }
}

export const getListAllCardsFiltereds = async (filters, token, page, issuerSelected, filterSelectedToDelete) => {
  try {
    const options = {
      method: 'GET',
      headers: {
        'Authorization': token,
        'issuer-Key': issuerSelected,
        'Content-type': 'application/json',
      },
      mode: 'cors'
    };
    const response = await fetch(withLanguageParameter(GET_LIST_CARDS(filters, page, filterSelectedToDelete)), options)
    const data = response.json();

    return data;
  }
  catch (e) {
    console.log('error:', e)
  }
}

export const getOperation = async (id, card_sha, token, issuerSelected) => {
  try {
    // console.log(token)
    const options = {
      method: "GET",
      headers: {
        'Authorization': `${token}`,
        'Content-type': 'application/json',
        'Issuer-Key': issuerSelected,
        'Card-Sha': card_sha
      },
      mode: 'cors',
    }
    const response = await fetch(withLanguageParameter(`${process.env.REACT_APP_API_OPERATION}/${id}`), options);
    console.log(response);
    const data = response.json();
    console.log(data);
    return data;
  }
  catch (e) {
    console.log('error:', e)
  }
}

export const getTransaction = async (id, token, issuerSelected) => {
  try {
    // console.log(token)
    const options = {
      method: "GET",
      headers: {
        Authorization: `${token}`,
        'Content-type': 'application/json',
        'Issuer-Key': issuerSelected
      },
      mode: 'cors',
    }
    const response = await fetch(withLanguageParameter(`${process.env.REACT_APP_API_AUTHENTICATION}/${id}`), options);
    console.log(response);
    const data = response.json();
    console.log(data);
    return data;
  }
  catch (e) {
    console.log('error:', e)
  }
}

export const getIssuers = async (token) => {
  console.log('loading_issuers');
  try {
    const options = {
      method: "GET",
      headers: {
        Authorization: `${token}`,
      },
      mode: 'cors',
    }
    const response = await fetch(withLanguageParameter(process.env.REACT_APP_API_ISSUER + "/list"), options);
    const data = response.json();
    return data;
  }
  catch (e) {
    console.log('error:', e)
  }
}

export const getListAllUsers = async (token, issuerSelected, pageCurrentDashboard, objUsersFiltered) => {
  try {
    // console.log(token)
    const options = {
      method: "GET",
      headers: {
        'Authorization': `${token}`,
        'Issuer-Key': issuerSelected
      },
      mode: 'cors',
    }
    // console.log(options);
    const response = await fetch(withLanguageParameter(GET_LIST_USERS(objUsersFiltered, pageCurrentDashboard)), options);
    const data = response.json();
    return data;
  }
  catch (e) {
    console.log('error:', e)
  }
}

export const getUserInfoService = async (token, idUser, issuerSelected, withOutAcquirerKey) => {
  try {
    // console.log(withOutAcquirerKey)
    // console.log(token)
    const options = {
      method: "GET",
      headers: {
        Authorization: `${token}`,
      },
      mode: 'cors',
    }
    console.log(issuerSelected)
    if (issuerSelected) options.headers['Issuer-Key'] = issuerSelected;
    const response = await fetch(withLanguageParameter(`${process.env.REACT_APP_API_USER}/${idUser}`), options);
    const data = response.json();
    return data;
  }
  catch (e) {
    console.log('error:', e)
  }
}

export const sentInvitationApi = async (token, issuerSelected, newUser) => {
  try {
    console.log(newUser)
    if (newUser && newUser.email && !newUser.role) newUser.role = "Administrador"
    const options = {
      method: "POST",
      headers: {
        'Authorization': `${token}`,
        'Content-type': 'application/json',
        'Issuer-Key': issuerSelected
      },
      body: JSON.stringify(newUser),
      mode: 'cors',
    }
    const response = await fetch(withLanguageParameter(`${process.env.REACT_APP_API_INVITATION}`), options);
    const data = response.json();
    return data;
  }
  catch (e) {
    console.log('error:', e)
  }
}

export const reSentCode = async (email) => {
  try {
    const body = {
      email: email
    }
    const options = {
      method: "POST",
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify(body),
      mode: 'cors',
    }
    const response = await fetch(withLanguageParameter(`${process.env.REACT_APP_API_FORWARDING}`), options);
    const data = response.json();
    return data;
  }
  catch (e) {
    console.log('error:', e)
  }
}

export const getAssociateDetailUser = async (id, pageCurrent, token) => {
  try {
    const options = {
      method: "GET",
      headers: {
        Authorization: `${token}`,
        //  'Acquirer-Key': issuerSelected
      },
      mode: 'cors',
    }
    const response = await fetch(withLanguageParameter(GET_ASSOCIATE_DETAIL(id, pageCurrent)), options);
    const data = response.json();
    return data;
  }
  catch (e) {
    console.log('error:', e)
  }
}

export const putUpdateUserInfoProfile = async (token, issuerSelected, user, user_key) => {
  try {
    const body = {
      "action": "update_profile",
      "firstName": user.name,
      "lastName": user.lastname,
      "type_country": user.countryCode,
      "country": user.country,
      "phone": user.phone
    }
    console.log(body)
    const options = {
      method: "PUT",
      headers: {
        Authorization: `${token}`,
        'Issuer-key': issuerSelected,
        'Content-type': 'application/json'
      },
      body: JSON.stringify(body),
      mode: 'cors',
    }
    console.log(options)
    //  console.log(JSON.stringify(body))
    const response = await fetch(withLanguageParameter(`${process.env.REACT_APP_API_USER}/${user_key}`), options);
    const data = response.json();
    return data;
  }
  catch (e) {
    console.log('error:', e)
  }
}

export const putUpdateUserInfoPassword = async (token, issuerSelected, user, user_key) => {
  try {
    console.log(user);
    const body = {
      "action": "change_pass",
      "passw": user.current,
      "passw_new": user.newPassword,
      "passw_renew": user.againPassword
    }
    console.log(body)
    const options = {
      method: "PUT",
      headers: {
        Authorization: `${token}`,
        'Issuer-key': issuerSelected,
        'Content-type': 'application/json'
      },
      body: JSON.stringify(body),
      mode: 'cors',
    }
    console.log(options)
    //  console.log(JSON.stringify(body))
    const response = await fetch(withLanguageParameter(`${process.env.REACT_APP_API_USER}/${user_key}`), options);
    const data = response.json();
    return data;
  }
  catch (e) {
    console.log('error:', e)
  }
}

export const putUpdateUserInfo = async (token, issuerSelected, user) => {
  try {
    const body = {
      "action": "update_user",
      "firstName": user.name,
      "lastName": user.lastname,
      "type_country": user.type_country,
      "country": user.country,
      "phone": user.mobile,
      "role": user.role,
      "state": user.state
    }
    console.log(user)
    const options = {
      method: "PUT",
      headers: {
        'Authorization': `${token}`,
        'issuer-key': issuerSelected,
        'Content-type': 'application/json'
      },
      body: JSON.stringify(body),
      mode: 'cors',
    }
    console.log(options)
    //  console.log(JSON.stringify(body))
    const response = await fetch(withLanguageParameter(`${process.env.REACT_APP_API_USER}/${user.user_key}`), options);
    const data = response.json();
    return data;
  }
  catch (e) {
    console.log('error:', e)
  }
}

export const putUserImage = async (token, issuerSelected, base64, idUser) => {
  try {
    const body = {
      "action": "update_image",
      "image": base64
    }
    console.log(idUser)
    console.log(base64)
    const options = {
      method: "PUT",
      headers: {
        Authorization: `${token}`,
        'Issuer-key': issuerSelected,
        // 'Content-type': 'application/json'
      },
      body: JSON.stringify(body),
      mode: 'cors',
    }
    console.log(options)
    //  console.log(JSON.stringify(body))
    const response = await fetch(withLanguageParameter(`${process.env.REACT_APP_API_USER}/${idUser}`), options);
    const data = response.json();
    return data;
  }
  catch (e) {
    console.log('error:', e)
  }
}

export const deleteUser = async (token, issuerSelected, idUser) => {
  try {
    const body = {
      "action": "delete_user"
    }
    const options = {
      method: "PUT",
      headers: {
        Authorization: `${token}`,
        'Issuer-key': issuerSelected,
        // 'Content-type': 'application/json'
      },
      body: JSON.stringify(body),
      mode: 'cors',
    }
    console.log(options)
    //  console.log(JSON.stringify(body))
    const response = await fetch(withLanguageParameter(`${process.env.REACT_APP_API_USER}/${idUser}`), options);
    const data = response.json();
    return data;
  }
  catch (e) {
    console.log('error:', e)
  }
}

export const cancelInvitationApi = async (token, issuerSelected, idUser) => {
  try {
    const body = {
      "action": "cancel_invitation"
    }
    const options = {
      method: "PUT",
      headers: {
        Authorization: `${token}`,
        'Issuer-key': issuerSelected,
        // 'Content-type': 'application/json'
      },
      body: JSON.stringify(body),
      mode: 'cors',
    }
    console.log(options)
    //  console.log(JSON.stringify(body))
    const response = await fetch(withLanguageParameter(`${process.env.REACT_APP_API_USER}/${idUser}`), options);
    const data = response.json();
    return data;
  }
  catch (e) {
    console.log('error:', e)
  }
}

export const reSentInvitationApi = async (token, issuerSelected, idUser, role) => {
  try {
    const body = {
      "action": "send_invitation",
      "role": role
    }
    const options = {
      method: "PUT",
      headers: {
        Authorization: `${token}`,
        'Issuer-key': issuerSelected,
        // 'Content-type': 'application/json'
      },
      body: JSON.stringify(body),
      mode: 'cors',
    }
    console.log(options)
    //  console.log(JSON.stringify(body))
    const response = await fetch(withLanguageParameter(`${process.env.REACT_APP_API_USER}/${idUser}`), options);
    const data = response.json();
    return data;
  }
  catch (e) {
    console.log('error:', e)
  }
}

export const updateAcquirerPicture = async (token, issuerSelected, idUser, base64) => {
  try {
    const body = {
      "image": base64
    }
    console.log("hola " + idUser)
    console.log(base64)
    console.log("issuerSelected -->" + issuerSelected)
    const options = {
      method: "PUT",
      headers: {
        Authorization: `${token}`,
        'Content-type': 'application/json'
      },
      pathParameters: {
        'issuer_key': issuerSelected
      },
      body: JSON.stringify(body),
      mode: 'cors'
    }
    console.log(options)
    const response = await fetch(withLanguageParameter(`${process.env.REACT_APP_API_ISSUER}/${issuerSelected}`), options);
    console.log(response)
    const data = response.json();
    return data;
  }
  catch (e) {
    console.log('error:', e)
  }
}


export const updateUserProfileInfo = async (token, acquirerSelected, user, user_key) => {
  try {
    const body = {
      "action": "update_profile",
      "firstName": user.name,
      "lastName": user.lastname,
      "type_country": user.countryCode,
      "country": user.country,
      "phone": user.phone
    }
    console.log(body)
    const options = {
      method: "PUT",
      headers: {
        Authorization: `${token}`,
        'Acquirer-key': acquirerSelected,
        'Content-type': 'application/json'
      },
      body: JSON.stringify(body),
      mode: 'cors',
    }
    console.log(options)
    const response = await fetch(withLanguageParameter(`${process.env.REACT_APP_API_USER}/${user_key}`), options);
    const data = response.json();
    return data;
  }
  catch (e) {
    console.log('error:', e)
  }
}

export const updateUserProfilePassword = async (token, acquirerSelected, user, user_key) => {
  try {
    console.log(user);
    const body = {
      "action": "change_pass",
      "passw": user.current,
      "passw_new": user.newPassword,
      "passw_renew": user.againPassword
    }
    console.log(body)
    const options = {
      method: "PUT",
      headers: {
        Authorization: `${token}`,
        'Acquirer-key': acquirerSelected,
        'Content-type': 'application/json'
      },
      body: JSON.stringify(body),
      mode: 'cors',
    }
    console.log(options)
    //  console.log(JSON.stringify(body))
    const response = await fetch(withLanguageParameter(`${process.env.REACT_APP_API_USER}/${user_key}`), options);
    const data = response.json();
    return data;
  }
  catch (e) {
    console.log('error:', e)
  }
}

export const postCard = async (token, issuer, card) => {
  try {
    const body = {
      pan: card.card,
      brand_one_letter: card.brand_one_letter,
      authentication_method: 'otp',
      country_code: card.country_code,
      phone_number: card.phone,
      email: card.email
    }
    console.log(body);
    const options = {
      method: "POST",
      headers: {
        'Content-type': 'application/json',
        'Authorization': token,
        'Issuer-key': issuer,
        'Channel': "CM-R"
      },
      body: JSON.stringify(body),
      mode: 'cors',
    }
    const response = await fetch(withLanguageParameter(`${process.env.REACT_APP_API_CARD}`), options);
    const data = response.json();
    return data;
  }
  catch (e) {
    console.log('error:', e)
  }
}

export const fetchCardApi = async payload => {
  try {
    const options = {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Authorization': payload.token,
        'Issuer-Key': payload.issuerKey,
        'Channel': payload.channel,
        'Content-Type': 'application/json'
      }
    }
    const response = await fetch(withLanguageParameter(`${process.env.REACT_APP_API_CARD}/${payload.panEncrypted}`), options)
    return response.json()
  } catch (e) {
    console.log('fetchCardApi error', e)
  }
}

export const putCard = async payload => {
  try {
    const body = {
      'pan': payload.pan,
      'enrollment_status': payload.enrollment_status,
      'authentication_method': payload.authentication_method,
      'country_code': payload.country_code,
      'phone_number': payload.phone_number,
      'email': payload.email
    };
    const options = {
      method: 'PUT',
      mode: 'cors',
      headers: {
        'Authorization': payload.token,
        'Issuer-Key': payload.issuerKey,
        'Content-Type': 'application/json',
        'Channel': 'CM-R'
      },
      body: JSON.stringify(body),
    }
    const response = await fetch(withLanguageParameter(`${process.env.REACT_APP_API_CARD}`), options)
    return response.json()
  } catch (e) {
    console.log('putCard error', e)
  }
}

export const fetchTransactionsByCardApi = async (card, token, page, viewCard, issuerSelected) => {
  try {
    const options = {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Authorization': token,
        'Issuer-Key': issuerSelected,
        'Content-Type': 'application/json'
      }
    }
    console.log(options);
    const response = await fetch(withLanguageParameter(GET_LIST_TRANSACTIONS_BY_CARD(card, page, viewCard)), options)
    return response.json()
  } catch (e) {
    console.log('fetchTransactionsByCardApi error', e)
  }
}

export const fetchOperationsByCardApi = async (card, token, page, issuerSelected) => {
  try {
    const options = {
      method: 'GET',
      headers: {
        'Authorization': token,
        'Issuer-Key': issuerSelected,
        'Content-type': 'application/json',
      },
      mode: 'cors'
    }
    console.log(options);
    const response = await fetch(withLanguageParameter(GET_LIST_OPERATIONS_BY_CARD(card, page)), options)
    return response.json()
  } catch (e) {
    console.log('fetchOperationsByCardApi error', e)
  }
}

export const fetchWhiteListByCardApi = async (card, token, page, issuerSelected) => {
  try {
    const options = {
      method: 'GET',
      headers: {
        'Authorization': token,
        'Issuer-Key': issuerSelected,
        'Content-type': 'application/json',
      },
      mode: 'cors'
    }
    console.log(options);
    const response = await fetch(withLanguageParameter(GET_LIST_WHITELIST_BY_CARD(card, page)), options)
    return response.json()
  } catch (e) {
    console.log('fetchOperationsByCardApi error', e)
  }
}

export const putCommerce = async payload =>{

  try {
    const body = {
      'pan': payload.pan,
      'cod_commerce': payload.merchantId,
      'state': payload.state
    };
    const options = {
      method: 'PUT',
      mode: 'cors',
      headers: {
        'Authorization': payload.token,
        'Issuer-Key': payload.issuerKey,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body),
    }
    const response = await fetch(withLanguageParameter(`${process.env.REACT_APP_API_WHITELIST}`), options)
    return response.json()
  } catch (e) {
    console.log('putCommerceInWhiteList error', e)
  }
}

// SESSION STATUS
export const getSessionStatus = async (token) => {
  try {
    // console.log(token)
    const options = {
      method: "GET",
      headers: {
        'Authorization': `${token}`,
        'Content-type': 'application/json'
      },
      mode: 'cors'
    }

    const response = await fetch(withLanguageParameter(`${process.env.REACT_APP_API_VALID_SESSION}`), options);
    const data = response.json();
    return data;
  }
  catch (e) {
    console.log('error:', e)
  }
}

function download(blob, filename) {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  // the filename you want
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
}

export const exportAllOperationsFiltereds = async (filters, token, issuerSelected) => {
  try {
    const options = {
      method: 'GET',
      headers: {
        'Authorization': token,
        'issuer-Key': issuerSelected,
        'Content-type': 'application/json',
      },
      mode: 'cors'
    };
    const r = await fetch(withLanguageParameter(GET_LIST_OPERATIONS(filters, 0, null, true)), options).then(response => {
      if (response.status === 400) {
        return response.json().then(
          data => {
            console.log(data);
            return {
              success: false,
              message: data.meta.status.message_ilgn[0].value 
            }
          }
        );
      } else {
        if (response.status !== 200) {
          return {
            success: false,
            message: 'Ocurrió un problema'
          }
        } else {
          const filename =  response.headers.get('Content-Disposition').split('filename=')[1].replaceAll('"','');
          console.log(filename);
          response.blob().then(blob => download(blob, filename));
          return {
            success: true,
            message: 'Descarga correcta'
          }
        }
      }
    });
    return r;
  }
  catch (e) {
    console.log('error:', e)
  }
}

export const exportAllCardsFiltereds = async (filters, token, issuerSelected) => {
  try {
    const options = {
      method: 'GET',
      headers: {
        'Authorization': token,
        'issuer-Key': issuerSelected,
        'Content-type': 'application/json',
      },
      mode: 'cors'
    };
    const r = await fetch(withLanguageParameter(GET_LIST_CARDS(filters, 0, null, true)), options).then(response => {
      if (response.status === 400) {
        return response.json().then(
          data => {
            console.log(data);
            return {
              success: false,
              message: data.meta.status.message_ilgn[0].value 
            }
          }
        );
      } else {
        if (response.status !== 200) {
          return {
            success: false,
            message: 'Ocurrió un problema'
          }
        } else {
          const filename =  response.headers.get('Content-Disposition').split('filename=')[1].replaceAll('"','');
          console.log(filename);
          response.blob().then(blob => download(blob, filename));
          return {
            success: true,
            message: 'Descarga correcta'
          }
        }
      }
    });
    return r;
  }
  catch (e) {
    console.log('error:', e)
  }
}

export const exportAllAuthenticationsFiltereds = async (filters, token, issuerSelected) => {
  try {
    const options = {
      method: 'GET',
      headers: {
        'Authorization': token,
        'issuer-Key': issuerSelected,
        'Content-type': 'application/json',
      },
      mode: 'cors'
    };
    const r = await fetch(withLanguageParameter(GET_LIST_TRANSACTIONS(filters, 0, null, true)), options).then(response => {
      if (response.status === 400) {
        return response.json().then(
          data => {
            console.log(data);
            return {
              success: false,
              message: data.meta.status.message_ilgn[0].value 
            }
          }
        );
      } else {
        if (response.status !== 200) {
          return {
            success: false,
            message: 'Ocurrió un problema'
          }
        } else {
          const filename =  response.headers.get('Content-Disposition').split('filename=')[1].replaceAll('"','');
          console.log(filename);
          response.blob().then(blob => download(blob, filename));
          return {
            success: true,
            message: 'Descarga correcta'
          }
        }
      }
    });
    return r;
  }
  catch (e) {
    console.log('error:', e)
  }  
}

// Statistics API

export const fetchStatisticsAPI = async (token, issuerSelected) => {
  try {
    const options = {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Authorization': token,
        'Issuer-Key': issuerSelected,
        'Content-Type': 'application/json'
      }
    }
    console.log(options);
    const response = await fetch(withLanguageParameter(GET_LIST_STATISTICS()), options);
    return response.json();
  }
  catch (e) {
    console.log('fetchStatisticsAPI error:', e);
  }
}
