import { getListAllTransactionsFiltereds, getListAllOperationsFiltereds, fetchCardApi, getListAllCardsFiltereds, getAssociateDetailUser, getAuthorizationUser, getAuthorizationByCode, putAuthorizationByCode, postRecoveryPasswordUser, getListBrands, getListCountries, getTransaction, getOperation, getListAllUsers, getUserInfoService, sentInvitationApi, reSentCode, putUpdateUserInfo, putUserImage, deleteUser, cancelInvitationApi, reSentInvitationApi, updateAcquirerPicture, getIssuers, putUpdateUserInfoProfile, postCard, fetchTransactionsByCardApi, fetchOperationsByCardApi, putUpdateUserInfoPassword, exportAllOperationsFiltereds, exportAllCardsFiltereds, exportAllAuthenticationsFiltereds, fetchStatisticsAPI, putCard, fetchWhiteListByCardApi,putCommerce } from './services/services';
import { put, takeLatest, call, all } from 'redux-saga/effects';
import { AUTHENTICATION_ACTIONS, LOGIN_ACTIONS, MERCHANTS_ACTIONS, HOME_ACTIONS, TRANSACTION_ACTIONS, CARD_ACTIONS, USERS_ACTIONS, OPERATION_ACTIONS, STATS_ACTIONS,WHITELIST_ACTIONS } from './constants/actions';
import { receiveAuthLoginUser, receiveGetLoginByCodeUser, receiveResponseSendCode, receiveResponseResetPassword, receiveResponseListIssuersByUser, receiveResponserReSendCode } from './redux/actions/auth';

import { receiveGetTransactionList, receiveGetTransaction, receiveFetchTransactionsByCard, receiveExportAuthentications } from './redux/actions/queryauthentication'
import { receiveGetOperationList, receiveGetOperation, receiveFetchOperationsByCard, receiveExportOperations } from './redux/actions/queryoperation'
import { receiveFetchWhiteListByCard, receiveUpdateCommerce } from './redux/actions/whitelist'
import { receiveResponseGetUserActive, receiveResponseListBrands, receiveResponseListCountries, receiveResponseUpdateAcquirerPictureUi } from './redux/actions/home';
import { receiveresponseGetAssociateDetail, receiveresponseGetListUsers, receiveresponseGetUserInfo, receiveResponseDeleteUser, receiveResponseSentInvitation, receiveResponseUpdateImage, receiveResponseUpdateUserInfo, setUserProfileData, receiveUpdateProfileData, receiveUpdateProfilePassword } from './redux/actions/users';
import { receiveAddCard, receiveGetCardList, fetchCardSuccess, fetchCardFailure, receiveExportCards, receiveUpdateCard } from './redux/actions/querycard';
import { receiveFetchStatistics } from './redux/actions/stats';

// AUTH

function* authUser(action) {
  try {
    console.log(action);
    const response = yield call(getAuthorizationUser, action.user);
    yield put(receiveAuthLoginUser(response))
  }
  catch (e) {
    console.log(e)
  }
}

function* authUserWatcher(action) {
  yield takeLatest(LOGIN_ACTIONS.LOGIN_ACTIONS_LOGIN_USER, authUser)
}

function* getAuthUser(action) {
  try {
    const response = yield call(getAuthorizationByCode, action.code);
    yield put(receiveGetLoginByCodeUser(response))
  }
  catch (e) {
    console.log(e)
  }
}

function* getAuthUserWatcher(action) {
  yield takeLatest(LOGIN_ACTIONS.LOGIN_ACTIONS_VALIDATION_BY_CODE, getAuthUser)
}

function* sendUserCode(action) {
  try {
    const response = yield call(putAuthorizationByCode, action.token, action.code);
    console.log(response)
    yield put(receiveResponseSendCode(response))
  }
  catch (e) {
    console.log(e)
  }
}

function* sendUserCodeWatcher(action) {
  yield takeLatest(LOGIN_ACTIONS.LOGIN_ACTIONS_SEND_CODE, sendUserCode)
}
// RECOVERY PASSWORD

function* recoveryPassword(action) {
  try {
    const response = yield call(postRecoveryPasswordUser, action.email);
    console.log(response)
    yield put(receiveResponseResetPassword(response))
  }
  catch (e) {
    console.log(e)
  }
}

function* postRecoveryPasswordWatcher(action) {
  yield takeLatest(LOGIN_ACTIONS.LOGIN_ACTIONS_RESET_PASSWORD, recoveryPassword)
}


// HOME

function* listBrands(action) {
  try {
    const response = yield call(getListBrands, action.id, action.token, action.issuerSelected);
    yield put(receiveResponseListBrands(response))
  }
  catch (e) {
    console.log(e)
  }
}

function* listBrandsWatcher(action) {
  yield takeLatest(HOME_ACTIONS.HOME_ACTIONS_LIST_BRANDS_BY_ACQUIRER, listBrands)
}

// BRANDS AND CURRENCIES EDIT MERCHANT

function* listCountries(action) {
  console.log(action)
  try {
    const response = yield call(getListCountries);
    console.log(response)
    yield put(receiveResponseListCountries(response))
  }
  catch (e) {
    console.log(e)
  }
}

function* listCountriesByAcquirerWatcher(action) {
  yield takeLatest(HOME_ACTIONS.HOME_ACTIONS_LIST_COUNTRIES_BY_ACQUIRER, listCountries)
}

// TRANSACTIONS

function* getListTransactions(action) {
  console.log(action)
  try {
    const response = yield call(getListAllTransactionsFiltereds, action.filters, action.token, action.page, action.issuerSelected, '');
    console.log(response)
    yield put(receiveGetTransactionList(response))
  }
  catch (e) {
    console.log(e)
  }
}

function* getSentAllTransactionsFilteredsWatcher(action) {
  yield takeLatest(AUTHENTICATION_ACTIONS.AUTHENTICATION_ACTIONS_LIST_TRANSACTIONS, getListTransactions)
}

// TRANSACTIONS CARDS

function* exportAuthentications(action) {
  console.log(action)
  try {
    const response = yield call(exportAllAuthenticationsFiltereds, action.filters, action.token, action.issuerSelected);
    yield put(receiveExportAuthentications(response))
  }
  catch (e) {
    console.log(e)
  }
}

function* exportAuthenticationsWatcher(action) {
  yield takeLatest(AUTHENTICATION_ACTIONS.AUTHENTICATION_ACTIONS_EXPORT_FILE, exportAuthentications)
}

// OPERATIONS

function* getListOperations(action) {
  console.log(action)
  try {
    const response = yield call(getListAllOperationsFiltereds, action.filters, action.token, action.page, action.issuerSelected, '');
    console.log(response)
    yield put(receiveGetOperationList(response))
  }
  catch (e) {
    console.log(e)
  }
}

function* getSentAllOperationsFilteredsWatcher(action) {
  yield takeLatest(OPERATION_ACTIONS.OPERATION_ACTIONS_LIST_OPERATIONS, getListOperations)
}

// EXPORT OPERATIONS

function* exportOperations(action) {
  console.log(action)
  try {
    const response = yield call(exportAllOperationsFiltereds, action.filters, action.token, action.issuerSelected);
    yield put(receiveExportOperations(response))
  }
  catch (e) {
    console.log(e)
  }
}

function* exportOperationsWatcher(action) {
  yield takeLatest(OPERATION_ACTIONS.OPERATION_ACTIONS_EXPORT_FILE, exportOperations)
}

// CARDS

function* getListCards(action) {
  console.log(action)
  try {
    const response = yield call(getListAllCardsFiltereds, action.filters, action.token, action.page, action.issuerSelected, '');
    console.log(response)
    yield put(receiveGetCardList(response));
  } catch (e) {
    console.log(e)
  }
}


function* getSentAllCardsFilteredsWatcher(action) {
  yield takeLatest(CARD_ACTIONS.CARD_ACTIONS_LIST_CARDS, getListCards)
}

// EXPORT CARDS

function* exportCards(action) {
  console.log(action)
  try {
    const response = yield call(exportAllCardsFiltereds, action.filters, action.token, action.issuerSelected);
    yield put(receiveExportCards(response))
  }
  catch (e) {
    console.log(e)
  }
}

function* exportCardsWatcher(action) {
  yield takeLatest(CARD_ACTIONS.CARD_ACTIONS_EXPORT_FILE, exportCards)
}

// TRANSACTIONS

function* listTransactionsByButton(action) {
  console.log(action)
  try {
    const response = yield call(getListAllTransactionsFiltereds, action.filters, action.token, action.page, action.issuerSelected, '');
    console.log(response)
    yield put(receiveGetTransactionList(response));
  } catch (e) {
    console.log(e)
  }
}

function* getlistTransactionsByButtonWatcher(action) {
	yield takeLatest(AUTHENTICATION_ACTIONS.AUTHENTICATION_ACTIONS_LIST_TRANSACTIONS_GO_TO_PAGE, getListTransactions)
}

// OPERATIONS

function* getlistOperationsByButtonWatcher(action) {
	yield takeLatest(OPERATION_ACTIONS.OPERATION_ACTIONS_LIST_OPERATIONS_GO_TO_PAGE, getListOperations)
}

// CARDS

function* getlistCardsByButtonWatcher(action) {
	yield takeLatest(CARD_ACTIONS.CARD_ACTIONS_LIST_CARDS_GO_TO_PAGE, getListCards)
}

// USERS
function* getlistUsersByButtonWatcher(action) {
	yield takeLatest(USERS_ACTIONS.USERS_ACTIONS_GO_TO_PAGE, getListUsers)
}

// DELETE FILTER CARDS
function* getListCardsFiltered(action) {
  console.log(action)
  try {
    const response = yield call(getListAllCardsFiltereds, action.filters, action.token, action.page, action.issuerSelected, action.filterSelectedToDelete);
    console.log(response)
    yield put(receiveGetCardList(response))
  }
  catch (e) {
    console.log(e)
  }
}

function* getSentAllCardsFilteredsToDeleteWatcher(action) {
  yield takeLatest(CARD_ACTIONS.CARD_ACTIONS_LIST_CARDS_DELETE_FILTERS, getListCardsFiltered)
}

// DELETE FILTER TRANSACTIONS
function* getListTransactionsFiltered(action) {
  console.log(action)
  try {
    const response = yield call(getListAllTransactionsFiltereds, action.filters, action.token, action.page, action.issuerSelected, action.filterSelectedToDelete);
    console.log(response)
    yield put(receiveGetTransactionList(response))
  }
  catch (e) {
    console.log(e)
  }
}

function* getSentAllTransactionsFilteredsToDeleteWatcher(action) {
  yield takeLatest(AUTHENTICATION_ACTIONS.AUTHENTICATION_ACTIONS_LIST_TRANSACTIONS_DELETE_FILTERS, getListTransactionsFiltered)
}

// DELETE FILTER OPERATIONS
function* getListOperationsFiltered(action) {
  console.log(action)
  try {
    const response = yield call(getListAllOperationsFiltereds, action.filters, action.token, action.page, action.issuerSelected, action.filterSelectedToDelete);
    console.log(response)
    yield put(receiveGetOperationList(response))
  }
  catch (e) {
    console.log(e)
  }
}

function* getSentAllOperationsFilteredsToDeleteWatcher(action) {
  yield takeLatest(OPERATION_ACTIONS.OPERATION_ACTIONS_LIST_OPERATIONS_DELETE_FILTERS, getListOperationsFiltered)
}

function* getTransactionInfo(action) {
  console.log(action)
  try {
    const response = yield call(getTransaction, action.id, action.token, action.issuerSelected);
    console.log(response)
    yield put(receiveGetTransaction(response))
  }
  catch (e) {
    console.log(e)
  }
}

function* getTransactionInfoWatcher(action) {
  yield takeLatest(AUTHENTICATION_ACTIONS.AUTHENTICATION_ACTIONS_GET_TRANSACTION, getTransactionInfo)
}

// GET OPERATION
function* getOperationInfo(action) {
  console.log(action)
  try {
    const response = yield call(getOperation, action.id, action.card_sha, action.token, action.issuerSelected);
    console.log(response)
    yield put(receiveGetOperation(response))
  }
  catch (e) {
    console.log(e)
  }
}

function* getOperationInfoWatcher(action) {
  yield takeLatest(OPERATION_ACTIONS.OPERATION_ACTIONS_GET_OPERATION, getOperationInfo)
}

// LIST ISSUERS

function* getListIssuers(action) {
  try {
    const response = yield call(getIssuers, action.token);
    console.log(response)
    yield put(receiveResponseListIssuersByUser(response))
  }
  catch (e) {
    console.log(e)
  }
}

function* getListIssuersWatcher(action) {
  yield takeLatest(LOGIN_ACTIONS.LOGIN_ACTIONS_VALIDATE_ISSUERS_AVAILABLE, getListIssuers)
}

// LIST USERS

function* getListUsers(action) {
  console.log(action)
  try {
    const response = yield call(getListAllUsers, action.token, action.issuerSelected, action.pageCurrentDashboard, action.objUsersFiltered);
    console.log(response)
    yield put(receiveresponseGetListUsers(response))
  }
  catch (e) {
    console.log(e)
  }
}

function* getListUsersWatcher(action) {
  yield takeLatest(USERS_ACTIONS.USERS_ACTIONS_LIST_USERS, getListUsers)
}

// USER INFO

function* getUserAllInfo(action) {
  console.log(action)
  try {
    const response = yield call(getUserInfoService, action.token, action.idUser, action.issuerSelected);
    console.log(response)
    yield put(receiveresponseGetUserInfo(response))
  }
  catch (e) {
    console.log(e)
  }
}

function* getUserAllInfoWatcher(action) {
  yield takeLatest(USERS_ACTIONS.USERS_ACTIONS_GET_INFO, getUserAllInfo)
}

// USER INVITATION

function* invitationUser(action) {
  console.log(action)
  try {
    const response = yield call(sentInvitationApi, action.token, action.issuerSelected, action.newUser);
    console.log(response)
    yield put(receiveResponseSentInvitation(response))
  }
  catch (e) {
    console.log(e)
  }
}

function* postInvitationUserWatcher(action) {
  yield takeLatest(USERS_ACTIONS.USERS_ACTIONS_SENT_INVITATION, invitationUser)
}

// RE SENT CODE

function* reSentCodeSaga(action) {
  // console.log(action)
  try {
    const response = yield call(reSentCode, action.email);
    // console.log(response)
    yield put(receiveResponserReSendCode(response))
  }
  catch (e) {
    console.log(e)
  }
}

function* postReSentCodeWatcher(action) {
  yield takeLatest(LOGIN_ACTIONS.LOGIN_ACTIONS_RESENT_CODE, reSentCodeSaga)
}

// GET ASSOCIATE DETAIL

function* getAssoiateDetail(action) {
  // console.log(action)
  try {
    const response = yield call(getAssociateDetailUser, action.id, action.pageCurrent, action.token);
    // console.log(response)
    yield put(receiveresponseGetAssociateDetail(response))
  }
  catch (e) {
    console.log(e)
  }
}

function* getAssoiateDetailWatcher(action) {
  yield takeLatest(USERS_ACTIONS.USERS_ACTIONS_GET_ASSOCIATE_DETAIL, getAssoiateDetail)
}

//INFO USER ACTIVE
function* getUserActiveInfo(action) {
  // console.log(action)
  try {
    const response = yield call(getUserInfoService, action.token, action.id, null, action.withOutAcquirerKey);
    // console.log(response)
    yield put(receiveResponseGetUserActive(response))
    yield put(setUserProfileData(response))
  }
  catch (e) {
    console.log(e)
  }
}

function* getUserActiveInfoWatcher(action) {
  yield takeLatest(HOME_ACTIONS.HOME_ACTIONS_GET_USER_ACTIVE, getUserActiveInfo)
}

// ADD CARD
function* postAddCard(action) {
  try {
    const response = yield call(postCard, action.token, action.issuerSelected, action.card);
    console.log(response)
    yield put(receiveAddCard(response))
  }
  catch (e) {
    console.log(e)
  }
}

function* postAddCardWatcher(action) {
  yield takeLatest(CARD_ACTIONS.CARD_ACTIONS_ADD_CARD, postAddCard)
}

//UPDATE USER
function* updateUserInfo(action) {
  console.log(action)
  try {
    const response = yield call(putUpdateUserInfo, action.token, action.issuerSelected, action.user);
    console.log(response)
    yield put(receiveResponseUpdateUserInfo(response))
  }
  catch (e) {
    console.log(e)
  }
}

function* updateUserInfoWatcher(action) {
  yield takeLatest(USERS_ACTIONS.USERS_ACTIONS_UPDATE_USER_INFO, updateUserInfo)
}

// UPDATE PROFILE USER
function* updateUserInfoProfile(action) {
  console.log(action)
  try {
    const response = yield call(putUpdateUserInfoProfile, action.token, action.issuerSelected, action.user, action.user_key);
    console.log(response)
    yield put(receiveUpdateProfileData(response))
  }
  catch (e) {
    console.log(e)
  }
}

function* updateUserInfoProfileWatcher(action) {
  yield takeLatest(USERS_ACTIONS.USERS_ACTIONS_PROFILE_UPDATE_DATA, updateUserInfoProfile)
}

// UPDATE PASSWORD USER
function* updateUserPassword(action) {
  console.log(action)
  try {
    const response = yield call(putUpdateUserInfoPassword, action.token, action.issuerSelected, action.user, action.user_key);
    console.log(response)
    yield put(receiveUpdateProfilePassword(response))
  }
  catch (e) {
    console.log(e)
  }
}

function* updateUserPasswordWatcher(action) {
  yield takeLatest(USERS_ACTIONS.USERS_ACTIONS_PROFILE_UPDATE_PASSWORD, updateUserPassword)
}

//PUT USER IMAGE
function* updateUserImage(action) {
  console.log(action)
  try {
    const response = yield call(putUserImage, action.token, action.issuerSelected, action.base64, action.idUser);
    console.log(response)
    yield put(receiveResponseUpdateImage(response))
  }
  catch (e) {
    console.log(e)
  }
}

function* updateUserImageWatcher(action) {
  yield takeLatest(USERS_ACTIONS.USERS_ACTIONS_UPDATE_IMAGE_PICTURE_USER_ACTIVE, updateUserImage)
}

// DELETE USER
function* deleteUserSaga(action) {
  console.log(action)
  try {
    const response = yield call(deleteUser, action.token, action.issuerSelected, action.idUser);
    console.log(response)
    yield put(receiveResponseDeleteUser(response))
  }
  catch (e) {
    console.log(e)
  }
}

function* deleteUserWatcher(action) {
  yield takeLatest(USERS_ACTIONS.USERS_ACTIONS_DELETE_USER, deleteUserSaga)
}

// CANCEL INVITATION
function* cancelInviationSaga(action) {
  console.log(action)
  try {
    const response = yield call(cancelInvitationApi, action.token, action.issuerSelected, action.idUser);
    console.log(response)
    yield put(receiveResponseDeleteUser(response))
  }
  catch (e) {
    console.log(e)
  }
}

function* cancelIntitationWatcher(action) {
  yield takeLatest(USERS_ACTIONS.USERS_ACTIONS_CANCEL_INVITATION, cancelInviationSaga)
}

// RESENT INVITATION
function* reSentInviationSaga(action) {
  console.log(action)
  try {
    const response = yield call(reSentInvitationApi, action.token, action.issuerSelected, action.idUser, action.role);
    console.log(response)
    yield put(receiveResponseDeleteUser(response))
  }
  catch (e) {
    console.log(e)
  }
}

function* reSentIntitationWatcher(action) {
  yield takeLatest(USERS_ACTIONS.USERS_ACTIONS_RESENT_INVITATION, reSentInviationSaga)
}

// SENT PICTURE TO CHANGE ACQUIRER IMAGE
function* sentPicureAcquirerSaga(action) {
  console.log(action)
  try {
    const response = yield call(updateAcquirerPicture, action.token, action.issuerSelected, action.idUser, action.base64);
    console.log(response)
    console.log("Actualizar-image-issuer")
    localStorage.setItem("issuer-image", response.image_url)
    yield put(receiveResponseUpdateAcquirerPictureUi(response))
    window.location.reload()
  }
  catch (e) {
    console.log(e)
  }
}

function* sentPicureAcquirerWatcher(action) {
  yield takeLatest(HOME_ACTIONS.HOME_ACTIONS_HANDLE_PICTURE_ACQUIRER, sentPicureAcquirerSaga)
}

// GET CARD DETAIL
function* fetchCard(action) {
  try {
    const data = yield call(fetchCardApi, action.payload)
    yield put(fetchCardSuccess(data))
  } catch (e) {
    yield put(fetchCardFailure(e.message))
  }
}

function* fetchCardWatcher() {
  yield takeLatest(CARD_ACTIONS.FETCH_CARD_REQUEST, fetchCard)
}

// PUT CARD 
function* putUpdateCard(action) {
  try {
    const response = yield call(putCard, action.payload)
    yield put(receiveUpdateCard(response))
  } catch (e) {
    console.log(e)
  }
}

function* putUpdateCardWatcher() {
  yield takeLatest(CARD_ACTIONS.CARD_ACTIONS_UPDATE_CARD, putUpdateCard)
}

// FETCH TRANSACTIONS BY CARD
function* fetchTransactionsByCard(action) {
  try {
    const data = yield call(fetchTransactionsByCardApi, action.card, action.token, action.page, action.viewCard, action.issuerSelected)
    yield put(receiveFetchTransactionsByCard(data))
  } catch (e) {
    console.log(e);
  }
}

function* fetchTransactionsByCardWatcher() {
  yield takeLatest(AUTHENTICATION_ACTIONS.AUTHENTICATION_ACTIONS_FECTH_TRANSACTIONS_BY_CARD, fetchTransactionsByCard)
}

// FETCH OPERATIONS BY CARD
function* fetchOperationsByCard(action) {
  try {
    const data = yield call(fetchOperationsByCardApi, action.card, action.token, action.page, action.issuerSelected)
    yield put(receiveFetchOperationsByCard(data))
  } catch (e) {
    console.log(e);
  }
}

function* fetchOperationsByCardWatcher() {
  yield takeLatest(OPERATION_ACTIONS.OPERATION_ACTIONS_FETCH_OPERATIONS_BY_CARD, fetchOperationsByCard)
}

// FETCH WHITELIST BY CARD
function* fetchWhiteListByCard(action) {
  try {
    const data = yield call(fetchWhiteListByCardApi, action.card, action.token, action.page, action.issuerSelected)
    yield put(receiveFetchWhiteListByCard(data))
  } catch (e) {
    console.log(e);
  }
}
function* fetchWhiteListByCardWatcher() {
  yield takeLatest(WHITELIST_ACTIONS.WHITELIST_ACTIONS_FETCH_WHITELIST_BY_CARD, fetchWhiteListByCard)
}

// PUT WHITELIST BY CARD
function* putUpdateCommerceInWhiteList(action) {
  try {
    console.log("PUT WHITELIST BY CARD")
    console.log("action.payload:",action.payload)
    const response = yield call(putCommerce, action.payload)
    console.log("response::",response)
    yield put(receiveUpdateCommerce(response))
  } catch (e) {
    console.log(e)
  }
}

function* putUpdateCommerceInWhiteListWatcher() {
  yield takeLatest(WHITELIST_ACTIONS.WHITELIST_ACTIONS_UPDATE_COMMERCE, putUpdateCommerceInWhiteList)
}


// CHANGE ORDER LIST

// USERS
function* listUsersChangeOrderWatcher(action) {
	yield takeLatest(USERS_ACTIONS.USERS_ACTIONS_CHANGE_ORDER, getListUsers);
}

// AUTHENTICATIONS
function* listAuthenticationsChangeOrderWatcher(action) {
	yield takeLatest(AUTHENTICATION_ACTIONS.AUTHENTICATION_ACTIONS_CHANGE_ORDER, getListTransactions);
}

// OPERATIONS
function* listOperationsChangeOrderWatcher(action) {
	yield takeLatest(OPERATION_ACTIONS.OPERATION_ACTIONS_CHANGE_ORDER, getListOperations);
}

// CARDS
function* listCardsChangeOrderWatcher(action) {
	yield takeLatest(CARD_ACTIONS.CARD_ACTIONS_CHANGE_ORDER, getListCards);
}

// STATISTICS
function* fetchStatistics(action) {
  try {
    const data = yield call(fetchStatisticsAPI, action.token, action.issuerSelected)
    yield put(receiveFetchStatistics(data))
  } catch (e) {
    console.log(e);
  }
}

function* fetchStatisticsWatcher() {
  yield takeLatest(STATS_ACTIONS.STATS_ACTIONS_FECTH_STATS, fetchStatistics)
}

export default function* mySaga() {
  yield all([
    putUpdateCardWatcher(),
    putUpdateCommerceInWhiteListWatcher(),
    fetchStatisticsWatcher(),
    exportAuthenticationsWatcher(),
    exportCardsWatcher(),
    exportOperationsWatcher(),
    listCardsChangeOrderWatcher(),
    listOperationsChangeOrderWatcher(),
    listAuthenticationsChangeOrderWatcher(),
    listUsersChangeOrderWatcher(),
    getlistUsersByButtonWatcher(),
    updateUserPasswordWatcher(),
    fetchTransactionsByCardWatcher(),
    fetchOperationsByCardWatcher(),
    fetchWhiteListByCardWatcher(),
    getSentAllCardsFilteredsToDeleteWatcher(),
    getOperationInfoWatcher(),
    getlistCardsByButtonWatcher(),
    getSentAllOperationsFilteredsToDeleteWatcher(),
    getlistOperationsByButtonWatcher(),
    getSentAllOperationsFilteredsWatcher(),
    getSentAllCardsFilteredsWatcher(),
    getlistTransactionsByButtonWatcher(),
    updateUserInfoProfileWatcher(),
    sentPicureAcquirerWatcher(),
    reSentIntitationWatcher(),
    cancelIntitationWatcher(),
    updateUserImageWatcher(),
    deleteUserWatcher(),
    updateUserInfoWatcher(),
    getAssoiateDetailWatcher(),
    postReSentCodeWatcher(),
    authUserWatcher(),
    getAuthUserWatcher(),
    sendUserCodeWatcher(),
    postRecoveryPasswordWatcher(),
    listBrandsWatcher(),
    listCountriesByAcquirerWatcher(),
    getSentAllTransactionsFilteredsWatcher(),
    getTransactionInfoWatcher(),
    getListIssuersWatcher(),
    getListUsersWatcher(),
    getUserAllInfoWatcher(),
    postInvitationUserWatcher(),
    getSentAllTransactionsFilteredsToDeleteWatcher(),
    getUserActiveInfoWatcher(),
    postAddCardWatcher(),
    fetchCardWatcher(),
  ])
}
