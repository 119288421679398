import { ENROLL_ACTIONS } from "../../constants/actions";
import { defaultValuesEnroll } from "../../constants/constants";
import Transform from "../../utils/transform";

let defaultState = defaultValuesEnroll();

const enroll = (state = defaultState, action) => {
  switch (action.type) {
    //  BULK LOAD
    case ENROLL_ACTIONS.ENROLL_ACTIONS_CLEAN_ALL_FILTERS:
      return {
        ...state,
        filtersBulkLoad: {
          fromDate: '',
          toDate: '',
          fileName: '',
          stateBatch: '',
          stateProcess: ''
        }
      }

    case ENROLL_ACTIONS.ENROLL_ACTIONS_UPLOAD_FILE:
      return {
        ...state,
        uploadling: true
      }
    case ENROLL_ACTIONS.ENROLL_ACTIONS_RECEIVE_RESPONSE_UPLOAD_FILE: {
      let result = 100;
      if (action.response === 'undefined' || action.response === undefined || action.response === {}) result = 98
      return {
        ...state,
        responseUploadFile: action.response,
        uploadlingPercent: result,
        uploadFileCompleted: true,
        disabledUploadFileIsLoaded: (result === 100) && true,
        uploadling: false,
      }
    }
    case ENROLL_ACTIONS.ENROLL_ACTIONS_INCREASE_PERCENT: {
      return {
        ...state,
        uploadlingPercent: state.uploadlingPercent + action.quantity
      }
    }

    case ENROLL_ACTIONS.ENROLL_ACTIONS_RESET_VALUES:
      return {
        ...state,
        uploadlingPercent: 0,
        isCorrectFormat: false,
        sentFromInput: false,
        disabledUploadFileIsLoaded: true,
        responseGetUrlUploadFile: {},
        responseUploadFile: null,
        fileUploaded: {},
        responseGetUrlFile: null
      }
    case ENROLL_ACTIONS.ENROLL_ACTIONS_GET_URL_UPLOAD_FILE:
      return {
        ...state,
      }
    case ENROLL_ACTIONS.ENROLL_ACTIONS_RECEIVE_RESPONSE_GET_URL_UPLOAD_FILE: {
      console.log(action.response, "action.response")
      const aux = action.response ? action.response : {}
      const auxUrl = (action.response === null || action.response === undefined || action.response === "undefined" ||
        (action.response && action.response.meta && !action.response.success)) ? true : false
      console.log(aux, "action.response")
      console.log(auxUrl)
      return {
        ...state,
        responseGetUrlUploadFile: aux,
        disabledUploadFileIsLoaded: auxUrl
      }
    }
    case ENROLL_ACTIONS.ENROLL_ACTIONS_SENT_FILE_TO_SERVER:
      return {
        ...state
      }
    case ENROLL_ACTIONS.ENROLL_ACTIONS_RECEIVE_RESPONSE_SENT_FILE_TO_SERVER: {
      console.log(action)
      return {
        ...state,
        responseFileSentToServer: action.response
      }
    }
    case ENROLL_ACTIONS.ENROLL_ACTIONS_HANDLE_VALUES_BULK_LOAD_FILTERS: {
      const aux = { ...state.filtersBulkLoad };
      if (action.id === "stateProcess") {

      }
      aux[action.id] = action.value;

      return {
        ...state,
        filtersBulkLoad: aux
      }
    }
    case ENROLL_ACTIONS.ENROLL_ACTIONS_SENT_ALL_FILTERS:
      return {
        ...state,
        responseGetListWithAllFilters: null

      }
    case ENROLL_ACTIONS.ENROLL_ACTIONS_RECEIVE_RESPONSE_SENT_ALL_FILTERS: {
      return {
        ...state,
        responseGetListWithAllFilters: action.response,
        getListWithAllFiltersIsLoaded: true,
        uploadFileCompleted: false
      }
    }
    case ENROLL_ACTIONS.ENROLL_ACTIONS_UPDATE_PERCENT_LOADED:
      return {
        ...state,
        uploadlingPercent: action.percent,
        disabledUploadFileIsLoaded: action.percent === 100 ? true : false
      }
    case ENROLL_ACTIONS.ENROLL_ACTIONS_RESET_EDIT_MERCHANT_VALUES:
      return {
        ...state,
        isLoadedMerchant: false,
        showModalConfirmUpdate: false
      }
    case ENROLL_ACTIONS.ENROLL_ACTIONS_DISABLED_BUTTON_UPLOAD:
      return {
        ...state,
        disabledUploadFileIsLoaded: action.value
      }
    case ENROLL_ACTIONS.ENROLL_ACTIONS_RESET_VALUES_BULK_LOAD: {
      console.log("ENROLL_ACTIONS.ENROLL_ACTIONS_RESET_VALUES_BULK_LOAD")
      return {
        ...state,
        getListWithAllFiltersIsLoaded: false,
        merchantsLoaded: false
      }
    }
    case ENROLL_ACTIONS.ENROLL_ACTIONS_RECEIVE_RESPONSE_GENERATE_URL_DOWNLOAD_FILE:
      return {
        ...state,
        responseGetUrlFile: action.response
      }
    case ENROLL_ACTIONS.ENROLL_ACTIONS_CLOSE_MODAL_EDIT_MERCHANT:
      return {
        ...state,
        showModalConfirmUpdate: false
      }
    case ENROLL_ACTIONS.ENROLL_ACTIONS_RESET_VALUES_MERCHANTS_FILTERS: {
      const aux = {
        fromDate: '',
        toDate: '',
        merchantName: '',
        merchantCode: '',
        state: '',
        brand: ''
      }
      return {
        ...state,
        filter: aux,
        merchantsLoaded: false
      }
    }
    case ENROLL_ACTIONS.ENROLL_ACTIONS_DELETE_FILTERS: {
      const aux = { ...state.filter };
      console.log(action)
      console.log(aux)
      aux[action.propertyName] = ''
      return {
        ...state,
        filter: aux
      }
    }
    case ENROLL_ACTIONS.ENROLL_ACTIONS_BULK_LOAD_DELETE_FILTERS: {
      const aux = { ...state.filtersBulkLoad };
      console.log(action)
      console.log(aux)
      aux[action.propertyName] = ''
      return {
        ...state,
        filtersBulkLoad: aux
      }
    }
    case ENROLL_ACTIONS.ENROLL_ACTIONS_CHANGE_ORDER: {
      const orderBy = {
        field: action.field,
        direction: action.direction
      }

      return {
        ...state,
        orderBy: orderBy
      }
    }
    // FIN BULK LOAD
    default:
      return state
  }
}

export default enroll;