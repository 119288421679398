import { monthNames, RESET_OBJ_MERCHANTS } from "../constants/constants";
import { AUTH_USER } from "../constants/routes";

class Transform {

  static encryptNumber(number) {
    let result = '';

    if (number) {
      number = number.toString();
      const firstPart = number.slice(0, 7)
      const secondPart = number.slice(12, 16)
      result = firstPart + '*****' + secondPart
    }
    return result;
  }

  static modelToRequestEnrollList(filters) {
    let result = [];
    if (filters) {
      if (filters.fromDateEnrollment) {
        let convertedDate = filters.fromDateEnrollment.split("/")
        convertedDate = convertedDate.reverse()
        convertedDate = convertedDate.join("-")
        result.push({ name: 'date_start', value: convertedDate })
      }
      if (filters.toDateEnrollment) {
        let convertedDate = filters.toDateEnrollment.split("/")
        convertedDate = convertedDate.reverse()
        convertedDate = convertedDate.join("-")
        result.push({ name: 'date_end', value: convertedDate })
      }
      if (filters.nameFileEnrollment) result.push({ name: 'name_upload', value: filters.nameFileEnrollment })
      if (filters.statusEnrollment) result.push({ name: 'state_process', value: filters.statusEnrollment })
    }
    return result;
  }

  static modelToRequestMerchantsList(filters) {
    if (filters) {
      // console.log(filters)
      if (filters.fromDate) {
        let convertedDate = filters.fromDate.split("/")
        convertedDate = convertedDate.reverse()
        convertedDate = convertedDate.join("-")
        filters.fromDate = convertedDate;
      }
      if (filters.toDate) {
        let convertedDate = filters.toDate.split("/")
        convertedDate = convertedDate.reverse()
        convertedDate = convertedDate.join("-")
        filters.toDate = convertedDate;
      }
      // if (filters.merchantName) filters.merchantName = 
      // if (filters.merchantCode) result.push({name: 'merchant_key"', value: filters.merchantCode})

      if (filters.state) filters.state = (filters.state === 'Afiliado') ? 'Habilitado' : 'Deshabilitado'
      if (filters.brand) filters.brands = filters.brand.toUpperCase()
    }
    return filters;
  }

  static modelToRequestUsersList(filters) {
    if (filters) {
      // console.log(filters)
      if (filters.fromDateUser) {
        let convertedDate = filters.fromDateUser.split("/")
        convertedDate = convertedDate.reverse()
        convertedDate = convertedDate.join("-")
        filters.fromDateUser = convertedDate;
      }
      if (filters.toDateUser) {
        let convertedDate = filters.toDateUser.split("/")
        convertedDate = convertedDate.reverse()
        convertedDate = convertedDate.join("-")
        filters.toDateUser = convertedDate;
      }
      // if (filters.merchantName) filters.merchantName = 
      // if (filters.merchantCode) result.push({name: 'merchant_key"', value: filters.merchantCode})

      // if (filters.state) filters.state = (filters.state === 'Afiliado') ? 'Habilitado' : 'Deshabilitado'
      // if (filters.brand) filters.brands = filters.brand.toUpperCase()
    }
    return filters;
  }

  static modelToQueryFilters(filters) {
    let result = {};
    // console.log(filters)
    if (filters) {
      if (filters.fromDateQuery) {
        let convertedDate = filters.fromDateQuery.split("/")
        convertedDate = convertedDate.reverse()
        convertedDate = convertedDate.join("-")
        //  result.push({name: 'date_start', value: convertedDate})
        filters.fromDateQuery = convertedDate
      }
      // console.log(filters)
      if (filters.toDateQuery) {
        let convertedDate = filters.toDateQuery.split("/")
        convertedDate = convertedDate.reverse()
        convertedDate = convertedDate.join("-")
        filters.toDateQuery = convertedDate
        //  result.push({name: 'date_end', value: convertedDate})
      }
      if (!filters.authResultQuery) filters.authResultQuery = 'Todos'
      if (!filters.maskTypeQuery) filters.maskTypeQuery = 'Enmascarada'
      if (!filters.currencyQuery) filters.currencyQuery = 'Todos'
    }
    // console.log(filters)
    result = filters
    return result;
  }


  static modelToAuthenticationFilters(filters) {
    let result = {};
    // console.log(filters)
    if (filters) {
      if (filters.fromDateAuthentication) {
        let convertedDate = filters.fromDateAuthentication.split("/")
        convertedDate = convertedDate.reverse()
        convertedDate = convertedDate.join("-")
        //  result.push({name: 'date_start', value: convertedDate})
        filters.fromDateAuthentication = convertedDate
      }
      // console.log(filters)
      if (filters.toDateAuthentication) {
        let convertedDate = filters.toDateAuthentication.split("/")
        convertedDate = convertedDate.reverse()
        convertedDate = convertedDate.join("-")
        filters.toDateAuthentication = convertedDate
        //  result.push({name: 'date_end', value: convertedDate})
      }
      if (!filters.authResultAuthentication) filters.authResultAuthentication = 'Todos'
      if (!filters.authTypeAuthentication) filters.authTypeAuthentication = 'Todos'
      if (!filters.authValueVersion) filters.authValueVersion = 'Todos'
      if (!filters.maskTypeAuthentication) filters.maskTypeAuthentication = 'Enmascarada'
      if (!filters.currencyAuthentication) filters.currencyAuthentication = 'Todos'
    }
    // console.log(filters)
    result = filters
    return result;
  }

  static modelToOperationFilters(filters) {
    let result = {};
    // console.log(filters)
    if (filters) {
      if (filters.fromDateOperation) {
        let convertedDate = filters.fromDateOperation.split("/")
        convertedDate = convertedDate.reverse()
        convertedDate = convertedDate.join("-")
        filters.fromDateOperation = convertedDate
      }
      // console.log(filters)
      if (filters.toDateOperation) {
        let convertedDate = filters.toDateOperation.split("/")
        convertedDate = convertedDate.reverse()
        convertedDate = convertedDate.join("-")
        filters.toDateOperation = convertedDate
      }
      if (!filters.maskTypeOperation) filters.maskTypeOperation = 'Enmascarada'
    }
    // console.log(filters)
    result = filters
    return result;
  }

  static modelToCardFilters(filters) {
    let result = {};
    // console.log(filters)
    if (filters) {
      if (filters.fromDateCard) {
        let convertedDate = filters.fromDateCard.split("/")
        convertedDate = convertedDate.reverse()
        convertedDate = convertedDate.join("-")
        filters.fromDateCard = convertedDate
      }
      // console.log(filters)
      if (filters.toDateCard) {
        let convertedDate = filters.toDateCard.split("/")
        convertedDate = convertedDate.reverse()
        convertedDate = convertedDate.join("-")
        filters.toDateCard = convertedDate
      }
      if (!filters.maskTypeCard) filters.maskTypeCard = 'Enmascarada'
    }
    // console.log(filters)
    result = filters
    return result;
  }

  static validateDataTableQueries(listResponse) {
    console.log(listResponse)
    let result = [];
    let obj = {
      id: {
        value: '',
        hidden: true
      },
      cardNumber: {
        value: '',
        typeCard: '',
        type: 'link',
        link: ''
      },
      creationDate: {
        value: ''
      },
      name: {
        value: '',
      },
      import_description: {
        value: ''
      },
      amount: {
        value: ''
      },
      result: {
        value: ''
      },
      messageVersionARES: {
        value: ''
      }
    }
    if (listResponse) {
      // console.log(listResponse)
      // console.log(obj)
      listResponse.forEach((element, index) => {
        if (obj && obj.id) obj.id.value = element.commerce;
        if (obj && obj.name) obj.name.value = element.commerce_name;
        if (obj && obj.key) {
          obj.key.value = element.commerce;
        }
        if (obj && obj.numberOrder) {
          obj.numberOrder.link = `authentication/detail/${element.key}`
          obj.numberOrder.value = element.number_order;

        }
        if (obj && obj.cardNumber) {
          obj.cardNumber.link = `authentication/detail/${element.key}`
          obj.cardNumber.value = element.number_card.number;
          obj.cardNumber.typeCard = element.number_card.type;
        }
        if (obj && obj.import_description) obj.import_description.value = element.import_description;
        if (obj && obj.result) obj.result.value = element.result
        if (obj && obj.amount) obj.amount.value = `${element.import || ''}`;
        if (obj && obj.creationDate) obj.creationDate.value = element.creationDate;
        if (obj && obj.messageVersionARES) obj.messageVersionARES.value = element.messageVersionARES;
        // console.log(obj)
        result.push(obj);
        obj = {
          id: {
            value: '',
            hidden: true
          },
          cardNumber: {
            value: '',
            typeCard: '',
            type: 'link',
            link: ''
          },
          creationDate: {
            value: ''
          },
          name: {
            value: '',
          },
          import_description: {
            value: ''
          },
          amount: {
            value: ''
          },
          result: {
            value: ''
          },
          messageVersionARES: {
            value: ''
          }
        }
        return obj
      })
      return result;
    }
  }

  static validateDataTableCards(listResponse) {
    console.log(listResponse)
    let result = [];
    let obj = {
      cardNumber: {
        value: '',
        type: 'link',
        link: ''
      },
      stateCard: {
        value: ''
      },
      brand: {
        value: ''
      },
      version:{
        value:''
      },
      enrollmentDate: {
        value: ''
      },
      demissionDate: {
        value: ''
      },
      updatedDate: {
        value: ''
      },
      
    }
    if (listResponse) {
      // console.log(listResponse)
      // console.log(obj)
      listResponse.forEach((element, index) => {
        if (obj && obj.cardNumber) {
          obj.cardNumber.link = `query/update/${element.key}`
          obj.cardNumber.value = element.cardNumber;
        }
        if (obj && obj.stateCard) obj.stateCard.value = element.stateCard;
        if (obj && obj.brand) obj.brand.value = element.brand;
        if (obj && obj.version) obj.version.value = element.version;
        if (obj && obj.enrollmentDate) obj.enrollmentDate.value = element.enrollmentDate;
        if (obj && obj.demissionDate) obj.demissionDate.value = element.demissionDate;
        if (obj && obj.updatedDate) obj.updatedDate.value = element.updatedDate;
        // console.log(obj)
        result.push(obj);
        obj = {
          cardNumber: {
            value: '',
            type: 'link',
            link: ''
          },
          stateCard: {
            value: ''
          },
          brand: {
            value: ''
          },
          version:{
            value:''
          },
          enrollmentDate: {
            value: ''
          },
          demissionDate: {
            value: ''
          },
          updatedDate: {
            value: ''
          },
          
        }
        return obj
      })
      return result;
    }
  }

  static validateDataTableOperations(listResponse) {
    // console.log(listResponse)
    let result = [];
    let obj = {
      cardNumber: {
        value: '',
        typeCard: '',
        type: 'link',
        link: ''
      },
      brand: {
        value: '',
      },
      creationDate: {
        value: ''
      },
      type: {
        value: ''
      },
      state: {
        value: ''
      }
    }
    if (listResponse) {
      // console.log(listResponse)
      // console.log(obj)
      listResponse.forEach((element, index) => {
        if (obj && obj.cardNumber) {
          obj.cardNumber.link = `operation/detail/${element.operation_id}/${element.card_sha}`
          obj.cardNumber.value = element.masked_card;
        }
        if (obj && obj.brand) obj.brand.value = element.brand;
        if (obj && obj.creationDate) obj.creationDate.value = element.created_at;
        if (obj && obj.type) obj.type.value = element.operation_type;
        if (obj && obj.state) obj.state.value = element.card_status_after_op;
        // console.log(obj)
        result.push(obj);
        obj = {
          cardNumber: {
            value: '',
            typeCard: '',
            type: 'link',
            link: ''
          },
          brand: {
            value: '',
          },
          creationDate: {
            value: ''
          },
          type: {
            value: ''
          },
          state: {
            value: ''
          }
        }
        return obj
      })
      return result;
    }
  }

  static validateDataTableAuthentication(listResponse) {
    // console.log("validateDataTableAuthentication", listResponse)
    let result = [];
    let obj = {
      cardNumber: {
        value: '',
        typeCard: '',
        type: 'link',
        link: ''
      },
      name: {
        value: '',
      },
      import_description: {
        value: ''
      },
      amount: {
        value: '',
        type:'amount'
      },
      result: {
        value: ''
      },
      cardStatus: {
        value: ''
      },
      creationDate: {
        value: '',
        type:'date_register'
      },
      messageVersionARES: {
        value: '',
        type:''
      },
    }
    if (listResponse) {
      // console.log(listResponse)
      // console.log(obj)
      listResponse.forEach((element, index) => {
        if (obj && obj.name) obj.name.value = element.commerce_name;
        if (obj && obj.key) {
          obj.key.value = element.commerce;
        }
        if (obj && obj.numberOrder) {
          obj.numberOrder.link = `authentication/detail/${element.key}`
          obj.numberOrder.value = element.number_order;

        }
        if (obj && obj.cardNumber) {
          obj.cardNumber.link = `authentication/detail/${element.key}`
          obj.cardNumber.value = element.number_card.number;
          obj.cardNumber.typeCard = element.number_card.type;
        }
        if (obj && obj.import_description) obj.import_description.value = element.import_description;
        if (obj && obj.result) obj.result.value = element.result
        if (obj && obj.amount) obj.amount.value = `${element.import || ''}`;
        if (obj && obj.cardStatus) obj.cardStatus.value = element.cardStatus;
        if (obj && obj.creationDate) obj.creationDate.value = element.creationDate;
        if (obj && obj.messageVersionARES) obj.messageVersionARES.value = element.messageVersionARES;
        result.push(obj);
        obj = {
          cardNumber: {
            value: '',
            typeCard: '',
            type: 'link',
            link: ''
          },
          name: {
            value: '',
          },
          import_description: {
            value: ''
          },
          amount: {
            value: '',
            type:'amount'
          },
          result: {
            value: ''
          },
          cardStatus: {
            value: ''
          },
          creationDate: {
            value: '',
            type:'date_register'
          },
          messageVersionARES: {
            value: '',
            type:''
          },
        }
        return obj
      })
      return result;
    }
  }

  static validateDataTableMerchants(listResponse) {
    let result = [];
    let obj = {
      id: {
        value: '',
        hidden: true
      },
      name: {
        value: '',
        type: 'link',
        link: ''
      },
      // code:{
      //   value:'',
      // },
      status: {
        value: ''
      },
      brand: {
        value: ''
      },
      date: {
        value: ''
      }
    };
    if (listResponse) {
      // console.log(listResponse)
      // console.log(obj)
      listResponse.forEach((element, index) => {
        if (element && element.key) {
          if (obj && obj.id) obj.id.value = element.commerce;
          if (obj && obj.name) {
            obj.name.value = element.commerce_name;
            obj.name.link = `merchant/edit/${element.key}`
          }
          if (obj && obj.code) {
            obj.code.value = element.commerce;
            obj.code.link = `merchant/edit/${element.key}`
          }
          if (obj && obj.status) obj.status.value = element.enabled ? 'Afiliado' : 'Desafiliado';
          if (obj && obj.brand) {
            const aux = element.brands.map(e => {
              if (e && e === 'DINC') e = 'Diners Club'
              if (e && e === 'MSCD') e = 'Mastercard'
              if (e && e === 'AMEX') e = 'American Express'
              if (e && e === 'VISA') e = 'Visa'
              return e;
            })
            console.log(aux)
            obj.brand.value = aux.join(", ");
          }
          if (obj && obj.date) obj.date.value = element.created_at;
          // console.log(obj)
          result.push(obj);
          obj = {
            id: {
              value: '',
              hidden: true
            },
            name: {
              value: '',
              type: 'link',
              link: 'merchant/edit/'
            },
            // code:{
            //   value: element.key,
            // },
            status: {
              value: ''
            },
            brand: {
              value: ''
            },
            date: {
              value: ''
            }
          }
          return obj
        }
      })
      return result;
    }
  }

  static validateDataTableBulkLoadFiles(listResponse) {
    let result = [];
    let obj = {
      name: {
        value: '',
      },
      recordsReceived: {
        value: '',
        // type:'link',
        // link:'home/merchant/edit/123456'
      },
      recordsProcessed: {
        value: ''
      },
      processedFile: { value: '', type: 'download', link: 'google.com.pe', name: 'archivo1.csv' },
      processState: {
        value: ''
      },
      dateUploaded: { value: '' },
      id: {
        value: '',
        hidden: false
      },
      batchId: {
        value: '',
        hidden: true
      }
    };
    if (listResponse) {
      // console.log(listResponse)
      // console.log(obj)
      listResponse.forEach((element, index) => {
        if (obj && obj.id) obj.id.value = element.user_id;
        if (obj && obj.name) obj.name.value = element.upload_name_batch;
        if (obj && obj.recordsReceived) obj.recordsReceived.value = element.records_received;
        if (obj && obj.recordsProcessed) obj.recordsProcessed.value = element.records_processed;
        if (obj && obj.processState) obj.processState.value = element.state_process_batch;
        if (obj && obj.processedFile) obj.processedFile.value = element.generate_name_batch;
        if (obj && obj.dateUploaded) obj.dateUploaded.value = element.date_time_upload_batch;
        if (obj && obj.batchId) obj.batchId.value = element.merchant_batch_id;
        // console.log(obj)
        result.push(obj);
        obj = {
          name: {
            value: '',
          },
          recordsReceived: {
            value: '',
            // type:'link',
            // link:'home/merchant/edit/123456'
          },
          recordsProcessed: {
            value: ''
          },
          processedFile: { value: '', type: 'download', link: 'google.com.pe', name: 'archivo1.csv' },
          processState: {
            value: ''
          },
          dateUploaded: { value: '' },
          id: {
            value: '',
            hidden: false
          },
          batchId: {
            value: '',
            hidden: true
          }
        }
        return obj
      })
      return result;
    }
  }

  static validateDataTableTransactionsByCard(listResponse) {
    let result = [];
    listResponse.forEach((element, index) => {
      const obj = {
        date: {
          value: element.creationDate,
        },
        commerce_name: {
          value: element.commerce_name
        },
        currency: {
          value: element.import_description,
        },
        amount: {
          value: element.import
        },
        result: {
          value: element.result
        },
        eci: {
          value: element.eci
        },
        card_status: {
          value: element.cardStatus
        },
        channel: {
          value: element.channel
        }
      };
      result.push(obj);
    });
    return result;
  }

  static validateDataTableOperationsByCard(listResponse) {
    console.log(listResponse);
    let result = [];
    listResponse.forEach((element, index) => {
      const obj = {
        operation_number: {
          value: element.operation_id,
        },
        brand: {
          value: element.brand
        },
        date: {
          value: element.created_at,
        },
        status_prev: {
          value: element.card_status_before_op
        },
        type: {
          value: element.operation_type
        },
        status_current: {
          value: element.card_status_after_op
        },
        channel: {
          value: element.enrollment_channel
        },
        user: {
          value: element.user_email
        }
      };
      result.push(obj);
    });
    return result;
  }

  static validateDataTableWhiteListByCard(listResponse) {
    console.log(listResponse);
    let result = [];
    listResponse.forEach((element, index) => {
      const obj = {
        icon: {
          value: element.code_commerce,
          type: 'icon',
          link: ''
        },
        merchant_name: {
          value: element.merchant_name,
        },
        code_commerce: {
          value: element.code_commerce
        },
        state: {
          value: element.state,
          description:element.description,
          type: 'state_commerce'
        },
        created_at: {
          value: element.created_at
        },
        delete_at: {
          value: element.delete_at,
          type: 'delete_at'
        },
        user: {
          value: element.user
        }
      };
      result.push(obj);
    });
    return result;
  }

  static validateDataTable(listResponse) {

    let result = [];
    let obj = {
      id: {}
    };
    if (listResponse) {
      console.log(listResponse)
      listResponse.forEach((element, index) => {
        return result;
      })
    }
  }

  static filterValues(listResponse, objFiltered) {
    let result = [];
    let arrayFields = []
    if (listResponse && objFiltered) {
      Object.keys(objFiltered).forEach((element, index) => {
        arrayFields.push({ key: element, value: Object.values(objFiltered)[index] })
      });
      if (arrayFields) {
        arrayFields = arrayFields.filter(field => (field && field.key && field.value))
        arrayFields.forEach(field => {
          listResponse.forEach(resp => {
            if (resp[field.key] === field.value) {
              result.push(resp)
            }
          })
        })
      }
    }
    return result;
  }

  static validateBrandToModel(brand) {
    let result = {
      position: null,
      code_commerce: "",
      code: "",
      name: "",
      enabled: false,
    }

    if (brand) {
      result.code_commerce = '';
      result.position = brand.position;
      result.code = brand.code;
      result.name = brand.name;
      result.enabled = true;
    }

    return result;
  }

  static validateDataTableStatisticByResult(listResponse,dateResponseStatistics) {
    let listResponseFilter = []
    let anio    =   parseInt(dateResponseStatistics.split(" ")[0].split("-")[0])
    let mes     =   parseInt(dateResponseStatistics.split(" ")[0].split("-")[1])
    
    for (let i = 0;i<listResponse.length; i++){
      if ((listResponse[i].year*100 + listResponse[i].month >= (anio - 1)*100 + mes) &&  
          (listResponse[i].year*100 + listResponse[i].month <= (anio)*100 + mes)
         )
      {
        listResponseFilter.push(listResponse[i])
      }
    }

    console.log("listResponseFilter:",listResponseFilter)

    let result = [];
    let resultTable = [];
    let headersListResponseFilter = [...listResponseFilter];
    let transStatusAllowed = ['Y','N','R','A','U','C']

    // Denegada N y R
    // Exitoso Y y A
    // Fallidass U
    // Incompletass C

    headersListResponseFilter = headersListResponseFilter.filter((row, index, self) => 
      index === self.findIndex((t) => (
        t.year === row.year && t.month === row.month
      ))
    );

    headersListResponseFilter.forEach( (anio) => {
      let obj = {
        "key": {
          value: (anio.year * 100) + anio.month,
          hidden: true
        },
        "description": {
          value: `${monthNames()[anio.month-1]} ${anio.year}`,
        }
      };
      transStatusAllowed.forEach( (transStatus) => {
        listResponseFilter.forEach( (element) => {
          if (transStatus === element.trans_status && anio.year === element.year && anio.month === element.month) {
            obj[transStatus] = {
              value: element.quantity,
            }
          }
        })
      });
      result.push(obj)
    });

    console.log(result);
    result.forEach((element) => {

      const trans_status_Y = (element.Y) ? element.Y.value : 0;
      const trans_status_A = (element.A) ? element.A.value : 0;
      const trans_status_N = (element.N) ? element.N.value : 0;
      const trans_status_R = (element.R) ? element.R.value : 0;
      const trans_status_U = (element.U) ? element.U.value : 0;
      const trans_status_C = (element.C) ? element.C.value : 0;

      const obj = {
        key: element.key,
        desc: element.description,
        exitosas: {
          value: trans_status_Y + trans_status_A
        },
        denegadas: {
          value: trans_status_N + trans_status_R
        },
        fallidas: {
          value: trans_status_U
        },
        incompletas: {
          value: trans_status_C
        },
        total: {
          value: trans_status_Y + trans_status_A + trans_status_N + trans_status_R + trans_status_U + trans_status_C
        }
      }
      resultTable.push(obj);
    });

    resultTable.sort((a, b) => (a.key.value > b.key.value ? -1 : 1));

    resultTable = this.validateDataEmpty(resultTable, "ByResult");

    // Agregar linea de total al final
    let total = {
      key: {
        value: 0,
        hidden: true,
      },
      desc: {
        value: 'Total'
      },
      exitosas: {
        value: 0
      },
      denegadas: {
        value: 0
      },
      fallidas: {
        value: 0
      },
      incompletas: {
        value: 0
      },
      total: {
        value: 0
      }
    }
    result.forEach((element) => {

      const trans_status_Y = (element.Y) ? element.Y.value : 0;
      const trans_status_A = (element.A) ? element.A.value : 0;
      const trans_status_N = (element.N) ? element.N.value : 0;
      const trans_status_R = (element.R) ? element.R.value : 0;
      const trans_status_U = (element.U) ? element.U.value : 0;
      const trans_status_C = (element.C) ? element.C.value : 0;

      total.exitosas.value = total.exitosas.value + trans_status_Y + trans_status_A;
      total.denegadas.value = total.denegadas.value + trans_status_N + trans_status_R;
      total.fallidas.value = total.fallidas.value + trans_status_U;
      total.incompletas.value = total.incompletas.value + trans_status_C;
      total.total.value = total.total.value + trans_status_Y + trans_status_A + trans_status_N + trans_status_R + trans_status_U + trans_status_C;
    });

    resultTable.push(total);

    return resultTable;
  }

  static validateDataTableStatisticByStatus(listResponse,dateResponseStatistics) {

    let listResponseFilter = []
    let anio    =   parseInt(dateResponseStatistics.split(" ")[0].split("-")[0])
    let mes     =   parseInt(dateResponseStatistics.split(" ")[0].split("-")[1])
    
    for (let i = 0;i<listResponse.length; i++){
      if ((listResponse[i].year*100 + listResponse[i].month >= (anio - 1)*100 + mes) &&  
          (listResponse[i].year*100 + listResponse[i].month <= (anio)*100 + mes)
         )
      {
        listResponseFilter.push(listResponse[i])
      }
    }

    console.log("listResponseFilter:",listResponseFilter)

    let result = [];
    let resultTable = [];
    let headersListResponseFilter = [...listResponseFilter];
    let transStatusAllowed = ['Y','N','R','A','U','C']

    headersListResponseFilter = headersListResponseFilter.filter((row, index, self) => 
      index === self.findIndex((t) => (
        t.year === row.year && t.month === row.month
      ))
    );

    headersListResponseFilter.forEach( (anio) => {
      let obj = {
        "key": {
          value: (anio.year * 100) + anio.month,
          hidden: true
        },
        "description": {
          value: `${monthNames()[anio.month-1]} ${anio.year}`,
        }
      };
      transStatusAllowed.forEach( (transStatus) => {
        listResponseFilter.forEach( (element) => {
          if (transStatus === element.trans_status && anio.year === element.year && anio.month === element.month) {
            obj[transStatus] = {
              value: element.quantity,
            }
          }
        })
      });
      result.push(obj)
    });

    // Asignar valores a las cabeceras correctas
    result.forEach((element) => {

      const trans_status_Y = (element.Y) ? element.Y.value : 0;
      const trans_status_A = (element.A) ? element.A.value : 0;
      const trans_status_N = (element.N) ? element.N.value : 0;
      const trans_status_R = (element.R) ? element.R.value : 0;
      const trans_status_U = (element.U) ? element.U.value : 0;
      const trans_status_C = (element.C) ? element.C.value : 0;

      const obj = {
        key: element.key,
        desc: element.description,
        exitosas: {
          value: trans_status_Y
        },
        denegadas: {
          value: trans_status_N
        },
        rechazadas: {
          value: trans_status_R
        },
        intentos: {
          value: trans_status_A
        },
        fallidas: {
          value: trans_status_U
        },
        incompletas: {
          value: trans_status_C
        },
        total: {
          value: trans_status_Y + trans_status_A + trans_status_N + trans_status_R + trans_status_U + trans_status_C
        }
      }
      resultTable.push(obj);
    });

    resultTable.sort((a, b) => (a.key.value > b.key.value ? -1 : 1));

    resultTable = this.validateDataEmpty(resultTable, "ByStatus");

    // Agregar linea de total al final
    let total = {
      key: {
        value: 0,
        hidden: true,
      },
      desc: {
        value: 'Total'
      },
      exitosas: {
        value: 0
      },
      denegadas: {
        value: 0
      },
      rechazadas: {
        value: 0
      },
      intentos: {
        value: 0
      },
      fallidas: {
        value: 0
      },
      incompletas: {
        value: 0
      },
      total: {
        value: 0
      }
    }
    result.forEach((element) => {
      const trans_status_Y = (element.Y) ? element.Y.value : 0;
      const trans_status_A = (element.A) ? element.A.value : 0;
      const trans_status_N = (element.N) ? element.N.value : 0;
      const trans_status_R = (element.R) ? element.R.value : 0;
      const trans_status_U = (element.U) ? element.U.value : 0;
      const trans_status_C = (element.C) ? element.C.value : 0;

      total.exitosas.value = total.exitosas.value + trans_status_Y;
      total.denegadas.value = total.denegadas.value + trans_status_N;
      total.rechazadas.value = total.rechazadas.value + trans_status_R;
      total.intentos.value = total.intentos.value + trans_status_A;
      total.fallidas.value = total.fallidas.value + trans_status_U;
      total.incompletas.value = total.incompletas.value + trans_status_C;
      total.total.value = total.total.value + trans_status_Y + trans_status_N + trans_status_R + trans_status_A + trans_status_U + trans_status_C;
    });
    resultTable.push(total);

    return resultTable;
  }

  static validateDataTableUser(listResponse) {
    let result = [];
    let obj = {
      // id: {
      //   value:'',
      //   hidden:false
      // },
      name: {
        value: '',
      },
      lastName: {
        value: '',
      },
      email: {
        value: '',
        type: 'link',
        link: 'users/edit/'
      },
      role: { value: '' },
      state: { value: '' },
      created_at: {value: ''}
    };
    if (listResponse) {
      // console.log(listResponse)
      // console.log(obj)
      listResponse.forEach((element, index) => {
        // if (obj && obj.id) obj.id.value = element.merchant_batch_id;
        if (obj && obj.name) obj.name.value = element.name;
        if (obj && obj.lastName) obj.lastName.value = element.lastname;
        if (obj && obj.email) {
          obj.email.value = element.email;
          obj.email.link = `users/edit/${element.user_key}`
        }
        if (obj && obj.role) obj.role.value = element.role;
        if (obj && obj.state) obj.state.value = element.state;
        if (obj && obj.created_at) obj.created_at.value = element.audit_attributes.created_at;
        // console.log(obj)
        result.push(obj);
        obj = {
          // id: {
          //   value:'',
          //   hidden:false
          // },
          name: {
            value: '',
          },
          lastName: {
            value: ''
          },
          email: {
            value: '',
            type: 'link',
            link: 'users/edit/'
          },
          role: { value: '' },
          state: { value: '' },
          created_at: { value: '' }
        }
        return obj
      })
      return result;
    }
  }

  static validateDataEmpty(list, type) {
    let result = [];
    if (list.length < 13) { // Si hay meses faltantes
      let range = [];
      const from = list[0].key.value.toString();
      const to = list[list.length - 1].key.value.toString();
      const fromDate = new Date(parseInt(from.slice(0, 4)),parseInt(from.slice(4, 6))-1);
      const toDate = new Date(parseInt(to.slice(0, 4)),parseInt(to.slice(4, 6))-1);

      if (this.monthDiff(toDate, fromDate) === 12) { // Tiene el mes 0 y 12 correctos
        console.log("validateEmpty", "1TO12");
        const month = fromDate.getMonth();
        const year = fromDate.getFullYear();
        range = this.buildFullRangeMonth(year, month);
      } else { // No se los extremos
        console.log("validateEmpty", "1NO12");
        let today = new Date();
        today.setDate(today.getDate() - 1);
        const month = today.getMonth();
        const year = today.getFullYear();
        range = this.buildFullRangeMonth(year, month);
      }

      range.forEach(elementRange => {
        let obj = list.find(o => o.key.value === elementRange.key);
        if (obj) {
          result.push(obj);
        } else {
          if (type === "ByResult") {
            let objResult = {
              key: {
                value: elementRange.key,
                hidden: true,
              },
              desc: {
                value: `${monthNames()[elementRange.month-1]} ${elementRange.year}`
              },
              exitosas: {
                value: 0
              },
              denegadas: {
                value: 0
              },
              fallidas: {
                value: 0
              },
              incompletas: {
                value: 0
              },
              total: {
                value: 0
              }
            }
            result.push(objResult);
          }
          if (type === "ByStatus") {
            let objStatus = {
              key: {
                value: elementRange.key,
                hidden: true,
              },
              desc: {
                value: `${monthNames()[elementRange.month-1]} ${elementRange.year}`
              },
              exitosas: {
                value: 0
              },
              denegadas: {
                value: 0
              },
              rechazadas: {
                value: 0
              },
              intentos: {
                value: 0
              },
              fallidas: {
                value: 0
              },
              incompletas: {
                value: 0
              },
              total: {
                value: 0
              }
            }
            result.push(objStatus);
          }
        }
      });
    } else {
      result = list;
    }
    console.log(result);
    return result;
  }

  static buildFullRangeMonth(initialYear, initialMonth) {
    let rangeMonths = [];
    for (let i = 0; i < 13; i++) {
      let tempDate = new Date(initialYear, initialMonth)
      tempDate.setMonth(tempDate.getMonth() - i);
      const tempYear = tempDate.getFullYear();
      const tempMonth = tempDate.getMonth() + 1;
      const tempKey = (tempYear * 100) + (tempMonth);
      const obj = {
        year: tempYear,
        month: tempMonth,
        key: tempKey
      }
      rangeMonths.push(obj);
    }
    return rangeMonths;
  }

  static monthDiff(d1, d2) {
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
  }

  static changeRoutesToProd() {
    const isProd = false;
    let AUTH_USER = ""
    let GET_AUTH_USER = ""
    let PUT_AUTH_USER = "";
    let POST_RESET_PASSWORD = "";
    let REGISTER_MERCHANT = ""
    let GET_URL_UPLOAD_FILE = "";

    if (!isProd) {
      AUTH_USER = "https://m1z46lzha9.execute-api.us-east-1.amazonaws.com/dev/auth";
      GET_AUTH_USER = "https://m1z46lzha9.execute-api.us-east-1.amazonaws.com/dev/auth";
      PUT_AUTH_USER = "https://m1z46lzha9.execute-api.us-east-1.amazonaws.com/dev/auth";
      POST_RESET_PASSWORD = "https://d3hdsh3c97.execute-api.us-east-1.amazonaws.com/dev/recovery";
      REGISTER_MERCHANT = "https://38l1cmgf5m.execute-api.us-east-1.amazonaws.com/development/merchants";
      GET_URL_UPLOAD_FILE = "https://aa5vor4ca2.execute-api.us-east-1.amazonaws.com/development/merchants/batch"
    } else {
      AUTH_USER = "https://68u5qoapac.execute-api.us-west-2.amazonaws.com/UAT/auth"
      GET_AUTH_USER = "https://68u5qoapac.execute-api.us-west-2.amazonaws.com/UAT/auth"
      PUT_AUTH_USER = "https://68u5qoapac.execute-api.us-west-2.amazonaws.com/UAT/auth";
      POST_RESET_PASSWORD = "https://eysn5ztw48.execute-api.us-west-2.amazonaws.com/UAT/recovery"
      REGISTER_MERCHANT = "https://0h9g04c6xh.execute-api.us-east-1.amazonaws.com/prod/merchants";
      GET_URL_UPLOAD_FILE = "https://0f7unoqds7.execute-api.us-east-1.amazonaws.com/prod/merchants/batch"
    }
    const aux = {
      isProd: isProd,
      auth_user: AUTH_USER,
      get_auth_user: GET_AUTH_USER,
      put_auth_user: PUT_AUTH_USER,
      post_auth_user: POST_RESET_PASSWORD,
      register_merchant: REGISTER_MERCHANT,
      get_url_upload_file: GET_URL_UPLOAD_FILE
    }
    // console.log(aux)
    return aux
  }
}

export default Transform;
