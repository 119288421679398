import { CARD_ACTIONS } from "../../constants/actions"

export const fetchCardRequest = payload => {
  return {
    type: CARD_ACTIONS.FETCH_CARD_REQUEST,
    payload
  }
}

export const fetchCardSuccess = payload => {
  return {
    type: CARD_ACTIONS.FETCH_CARD_SUCCESS,
    payload
  }
}

export const fetchCardFailure = payload => {
  return {
    type: CARD_ACTIONS.FETCH_CARD_FAILURE,
    payload
  }
}

export const getCardList = (filters, token, page, issuerSelected) => {
  return {
    type: CARD_ACTIONS.CARD_ACTIONS_LIST_CARDS,
    filters,
    token,
    page,
    issuerSelected
  }
}

export const exportCards = (filters, token, issuerSelected) => {
  return {
    type: CARD_ACTIONS.CARD_ACTIONS_EXPORT_FILE,
    filters,
    token,
    issuerSelected
  }
}

export const receiveExportCards = (response) => {
  return {
    type: CARD_ACTIONS.CARD_ACTIONS_RECEIVE_EXPORT_FILE,
    response
  }
}

export const resetExportCards = () => {
  return {
    type: CARD_ACTIONS.CARD_ACTIONS_RESET_EXPORT_FILE,
  }
}

export const receiveGetCardList = (response) => {
  return {
    type: CARD_ACTIONS.CARD_ACTIONS_RECEIVE_LIST_CARDS,
    response
  }
}

export const nextPage = () => {
  return {
    type: CARD_ACTIONS.CARD_ACTIONS_LIST_CARDS_NEXT_PAGE
  }
}

export const backPage = () => {
  return {
    type: CARD_ACTIONS.CARD_ACTIONS_LIST_CARDS_BACK_PAGE
  }
}

export const goToPage = (filters, page, token, issuerSelected) => {
  return {
    type: CARD_ACTIONS.CARD_ACTIONS_LIST_CARDS_GO_TO_PAGE,
    filters,
    page,
    token,
    issuerSelected
  }
}

export const resetFilters = () => {
  return {
    type: CARD_ACTIONS.CARD_ACTIONS_LIST_CARDS_RESET_FILTERS
  }
}

export const handleFilters = (id, value) => {
  return {
    type: CARD_ACTIONS.CARD_ACTIONS_LIST_CARDS_HANDLE_FILTERS,
    id,
    value
  }
}

export const deleteFilter = (filters, token, page, issuerSelected, filterSelectedToDelete, propertyName) => {
  return {
    type: CARD_ACTIONS.CARD_ACTIONS_LIST_CARDS_DELETE_FILTERS,
    filters,
    token,
    page,
    issuerSelected,
    filterSelectedToDelete,
    propertyName
  }
}

export const addCard = (token, issuerSelected, card) => {
  return {
    type: CARD_ACTIONS.CARD_ACTIONS_ADD_CARD,
    token,
    issuerSelected,
    card
  }
}

export const handleValuesAddCard = (id, value) => {
  return {
    type: CARD_ACTIONS.CARD_ACTIONS_HANDLE_VALUES_ADD_CARD,
    id,
    value
  }
}

export const receiveAddCard = (response) => {
  console.log(response);
  return {
    type: CARD_ACTIONS.CARD_ACTIONS_RECEIVE_ADD_CARD,
    response
  }
}

export const resetAddCard = () => {
  return {
    type: CARD_ACTIONS.CARD_ACTIONS_RESET_VALUES_ADD_CARD
  }
}

export const changeOrder = (field, direction, token, issuerSelected, page, filters) => {
  return {
    type: CARD_ACTIONS.CARD_ACTIONS_CHANGE_ORDER,
    field,
    direction,
    token,
    issuerSelected,
    page,
    filters
  }
}

export const updateCard = (payload) => {
  return {
    type: CARD_ACTIONS.CARD_ACTIONS_UPDATE_CARD,
    payload
  }
}

export const receiveUpdateCard = (response) => {
  return {
    type: CARD_ACTIONS.CARD_ACTIONS_RECEIVE_UPDATE_CARD,
    response
  }
}

export const resetUpdateCard = () => {
  return {
    type: CARD_ACTIONS.CARD_ACTIONS_RESET_UPDATE_CARD
  }
}