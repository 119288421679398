import { HOME_ACTIONS, MERCHANTS_ACTIONS } from "../../constants/actions"

export const updateList = (list) => {
  return {
    type: HOME_ACTIONS.HOME_ACTIONS_UPDATE_QUERIES,
    list
  }
}

export const getListBrands = (id, token, issuerSelected) => {
  return {
      type: HOME_ACTIONS.HOME_ACTIONS_LIST_BRANDS_BY_ACQUIRER,
      id,
      token,
      issuerSelected
  }
}

export const receiveResponseListBrands = (response) => {
  return {
      type: HOME_ACTIONS.HOME_ACTIONS_RECEIVE_RESPONSE_LIST_BRANDS_BY_ACQUIRER,
      response
  }
}

export const getListCountries = () => {
  return {
      type: HOME_ACTIONS.HOME_ACTIONS_LIST_COUNTRIES_BY_ACQUIRER
  }
}

export const receiveResponseListCountries = (response) => {
  return {
    type: HOME_ACTIONS.HOME_ACTIONS_RECEIVE_RESPONSE_LIST_COUNTRIES,
    response
  }
}

export const listAcquirersHome = () => {
  return {
    type: HOME_ACTIONS.HOME_ACTIONS_LIST_ACQUIRERS_LOADING,
  }
}

export const listAcquirersHomeLoaded = () => {
  return {
    type: HOME_ACTIONS.HOME_ACTIONS_LIST_ACQUIRERS_LOADED,
  }
}

export const resetBrandsMerchants = () => {
  return {
    type: HOME_ACTIONS.HOME_ACTIONS_RESET_BRANDS,
  }
}

export const selectAcquirerForDashboard = (acquirer) => {
  return {
    type: HOME_ACTIONS.HOME_ACTIONS_SELECT_ACQUIRER_DASHBOARD,
    acquirer
  }
}

export const selectIssuerForDashboard = (issuer) => {
  return {
    type: HOME_ACTIONS.HOME_ACTIONS_SELECT_ISSUER_DASHBOARD,  
    issuer
  }
}

export const getUserActive = (token, id, withOutAcquirerKey) => {
  return {
    type: HOME_ACTIONS.HOME_ACTIONS_GET_USER_ACTIVE,
    token,
    id,
    withOutAcquirerKey
  }
}

export const receiveResponseGetUserActive = (response) => {
  return {
    type: HOME_ACTIONS.HOME_ACTIONS_RECEIVE_RESPONSE_GET_USER_ACTIVE,
    response
  }
}

export const updateAcquirerPictureUi = (token, issuerSelected, idUser, base64) => {
  return {
    type: HOME_ACTIONS.HOME_ACTIONS_HANDLE_PICTURE_ACQUIRER,
    token,
    issuerSelected,
    idUser,
    base64
  }
}

export const receiveResponseUpdateAcquirerPictureUi = (response) => {
  return {
    type: HOME_ACTIONS.HOME_ACTIONS_RECEIVE_RESPONSE_HANDLE_PICTURE_ACQUIRER,
    response
  }
}
