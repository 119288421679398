import { USERS_ACTIONS } from "../../constants/actions";
import { defaultValuesUsers } from "../../constants/constants";

let defaultState = defaultValuesUsers();

const users = (state = defaultState, action) => {
  switch (action.type) {
    case USERS_ACTIONS.USERS_ACTIONS_HANDLE_VALUES: {
      const aux = { ...state.objUsersFiltered }
      var result = /^[a-zA-Z]+$/.test(action.value)
      console.log(result)
      console.log(action)
      if (!result && action.value.length === 0) {
        aux[action.id] = ""
      }
      if (result) {
        if (action.id === "name")
          aux[action.id] = action.value

        if (action.id === "lastName")
          aux[action.id] = action.value

        if (action.id === "role")
          aux[action.id] = action.value

        if (action.id === "state")
          aux[action.id] = action.value
      }
      else {
        if (action.id === "fromDateUser" || action.id === "toDateUser")
          aux[action.id] = action.value
      }
      if (action.id === "email" || action.id === "state") aux[action.id] = action.value
      return {
        ...state,
        objUsersFiltered: aux
      }
    }
    case USERS_ACTIONS.USERS_ACTIONS_LIST_USERS:
      return {
        ...state,
        changePageDashboard: false,
        allUserLoaded: false,
        responseListAllUsers: null,
        pageCurrentDashboard: action.pageCurrentDashboard
      }
    case USERS_ACTIONS.USERS_ACTIONS_RECEIVE_RESPONSE_LIST_USERS:
      return {
        ...state,
        responseListAllUsers: action.response,
        allUserLoaded: true
      }
    case USERS_ACTIONS.USERS_ACTIONS_GET_INFO:
      return {
        ...state,
        isUpdatedUserInfo: false
      }
    case USERS_ACTIONS.USERS_ACTIONS_SET_PROFILE_DATA:
      let data = {
        name: action.response.name,
        lastname: action.response.lastname,
        countryCode: action.response.type_country,
        country: action.response.country,
        phone: action.response.mobile,
        email: action.response.email
      }
      return {
        ...state,
        userProfileData: data
      }
    case USERS_ACTIONS.USERS_ACTIONS_DELETE_FILTERS: {
        const aux = { ...state.objUsersFiltered };
        console.log(action)
        console.log(aux)
        aux[action.propertyName] = ''
        return {
          ...state,
          objUsersFiltered: aux
        }
      }
    case USERS_ACTIONS.USERS_ACTIONS_RECEIVE_RESPONSE_GET_INFO: {
      console.log(action)
      return {
        ...state,
        responseUserInfo: action.response,
        userInfoLoaded: true
      }

    }
    case USERS_ACTIONS.USERS_ACTIONS_HANDLE_USER_INVITED_FIELDS: {
      const aux = { ...state.userInvited };
      aux[action.id] = action.value;
      console.log(aux)
      return {
        ...state,
        userInvited: aux
      }
    }
    case USERS_ACTIONS.USERS_ACTIONS_UPDATE_LIST_USERS:
      return {
        ...state,
        listAllUsers: action.list,
      }
    case USERS_ACTIONS.USERS_ACTIONS_SENT_INVITATION:
      return {
        ...state,
        sendingInvitation: true,
        responseSentInvitation: null
      }
    case USERS_ACTIONS.USERS_ACTIONS_RECEIVE_RESPONSE_SENT_INVITATION:
      return {
        ...state,
        responseSentInvitation: action.response,
        sendingInvitation: false
      }
    case USERS_ACTIONS.USERS_ACTIONS_RESET_VALUES: {
      console.log('reset values');
      const aux = {
        fromDateUser: '',
        toDateUser: '',
        name: '',
        lastName: '',
        email: '',
        role: '',
        state: ''
      }
      return {
        ...state,
        responseSentInvitation: null,
        objUsersFiltered: aux,
        allUserLoaded: false
      }
    }
    case USERS_ACTIONS.USERS_ACTIONS_GET_ASSOCIATE_DETAIL:
      return {
        ...state,
        associateDetailIsloaded: true,
        changePage: true,
        pageCurrent: action.pageCurrent
      }
    case USERS_ACTIONS.USERS_ACTIONS_RECEIVE_RESPONSE_GET_ASSOCIATE_DETAIL:
      return {
        ...state,
        changePage: false,
        responseAssociateDetail: action.response,
      }
    case USERS_ACTIONS.USERS_ACTIONS_NEXT_PAGE:
      return {
        ...state,
        pageCurrent: state.pageCurrent + 1,
        changePage: true
      }
    case USERS_ACTIONS.USERS_ACTIONS_BACK_PAGE:
      return {
        ...state,
        pageCurrent: state.pageCurrent - 1,
        changePage: true
      }
    case USERS_ACTIONS.USERS_ACTIONS_DASHBOARD_NEXT_PAGE:
      return {
        ...state,
        pageCurrentDashboard: state.pageCurrentDashboard + 1,
        changePageDashboard: true
      }
    case USERS_ACTIONS.USERS_ACTIONS_DASHBOARD_BACK_PAGE:
      return {
        ...state,
        pageCurrentDashboard: state.pageCurrentDashboard - 1,
        changePageDashboard: true
      }
    case USERS_ACTIONS.USERS_ACTIONS_UPDATE_USER_INFO:
      return {
        ...state,
        isUpdatedUserInfo: false,
        responseUpdatedUserInfo: null,
        isUpdatedUserInfoLoading: true

      }
    case USERS_ACTIONS.USERS_ACTIONS_RECEIVE_RESPONSE_UPDATE_USER_INFO:
      return {
        ...state,
        responseUpdatedUserInfo: action.response,
        isUpdatedUserInfo: true,
        isUpdatedUserInfoLoading: false
      }
    case USERS_ACTIONS.USERS_ACTIONS_DELETE_USER:
      return {
        ...state,
        userIsDeleted: false,
        responseUserDelete: null,
        userIsDeletedLoading: true

      }
    case USERS_ACTIONS.USERS_ACTIONS_RECEIVE_RESPONSE_DELETE_USER:
      return {
        ...state,
        responseUserDelete: action.response,
        userIsDeleted: true,
        userIsDeletedLoading: false
      }
    case USERS_ACTIONS.USERS_ACTIONS_RESET_VALUES_UPLOAD_IMAGE:
      return {
        ...state,
        responseUserImageUpdated: null,
        isUpdatedUserInfo: false,
        responseUserDelete: null,
        responseUpdatedUserInfo: null,
        associateDetailIsloaded: false,
        userInfoLoaded: false
      }
    case USERS_ACTIONS.USERS_ACTIONS_HANDLE_VALUES_UPDATE_INFO_USER: {
      const aux = { ...state.responseUserInfo }
      const aux2 = {}
      aux[action.id] = action.value

      return {
        ...state,
        newState: (action.id === 'role' || action.id === 'state') && state.responseUserInfo.state,
        responseUserInfo: aux,
        responseUpdatedUserInfo: null,
        userIsCancelInvitation: false,
        isChanging: true,
      }
    }
    case USERS_ACTIONS.USERS_ACTIONS_PROFILE_UPDATE_DATA_HANDLE: {
      const aux = { ...state.userProfileData };
      aux[action.id] = action.value;
      console.log(aux)
      return {
        ...state,
        userProfileData: aux
      }
    }
    case USERS_ACTIONS.USERS_ACTIONS_PROFILE_UPDATE_PASSWORD_HANDLE: {
      const aux = { ...state.userProfilePassword };
      aux[action.id] = action.value;
      console.log(aux)
      return {
        ...state,
        userProfilePassword: aux
      }
    }
    case USERS_ACTIONS.USERS_ACTIONS_PROFILE_UPDATE_DATA:
      return {
        ...state,
        userProfileDataUpdated: false,
        userProfileDataResponse: null,
        userProfileDataUpdating: true

      }
    case USERS_ACTIONS.USERS_ACTIONS_PROFILE_UPDATE_DATA_RECEIVE:
      return {
        ...state,
        userProfileDataUpdated: true,
        userProfileDataResponse: action.response,
        userProfileDataUpdatedValid: action.response.success,
        userProfileDataResponseMessage: action.response.users.meta.status.message_ilgn[0].value,
        userProfileDataUpdating: false

      }
    case USERS_ACTIONS.USERS_ACTIONS_PROFILE_UPDATE_PASSWORD:
      return {
        ...state,
        userProfilePasswordUpdated: false,
        userProfilePasswordResponse: null,
        userProfilePasswordUpdating: true
      }
    case USERS_ACTIONS.USERS_ACTIONS_PROFILE_UPDATE_PASSWORD_RECEIVE:
      return {
        ...state,
        userProfilePasswordUpdated: true,
        userProfilePasswordResponse: action.response,
        userProfilePasswordUpdatedValid: action.response.success,
        userProfilePasswordResponseMessage: action.response.users.meta.status.message_ilgn[0].value,
        userProfilePasswordUpdating: false
      }
    case USERS_ACTIONS.USERS_ACTIONS_RESET_UPDATE_DATA_VALUES:
      return {
        ...state,
        userProfileDataUpdated: false,
        userProfileDataResponse: null,
        userProfileDataUpdatedValid: false,
        userProfileDataResponseMessage: null,
        userProfileDataUpdating: false
      }
    case USERS_ACTIONS.USERS_ACTIONS_RESET_UPDATE_PASSWORD_VALUES:
        return {
          ...state,
          userProfilePasswordUpdated: false,
          userProfilePasswordResponse: null,
          userProfilePasswordUpdatedValid: false,
          userProfilePasswordResponseMessage: null,
          userProfilePasswordUpdating: false
        }
    case USERS_ACTIONS.USERS_ACTIONS_UPDATE_IMAGE_PICTURE_USER_ACTIVE:
      return {
        ...state,
        userImageIsChanging: true,
        responseUserImageUpdated: null,
        userImageIsChanged: false,

      }
    case USERS_ACTIONS.USERS_ACTIONS_RECEIVE_RESPONSE_UPDATE_IMAGE_PICTURE_USER_ACTIVE:
      return {
        ...state,
        responseUserImageUpdated: action.response,
        userImageIsChanging: false,
        userImageIsChanged: true
      }
    case USERS_ACTIONS.USERS_ACTIONS_CANCEL_INVITATION:
      return {
        ...state,
        responseUserCancelInvitation: null,
        userIsCancelInvitationLoading: true,
        userIsCancelInvitation: false
      }
    case USERS_ACTIONS.USERS_ACTIONS_RECEIVE_RESPONSE_CANCEL_INVITATION:
      return {
        ...state,
        responseUserCancelInvitation: action.response,
        userIsCancelInvitation: true,
        userIsCancelInvitationLoading: false
      }
    case USERS_ACTIONS.USERS_ACTIONS_RESENT_INVITATION:
      return {
        ...state,
        responseUserReSentInvitation: null,
        userIsReSentInvitationLoading: true,
        userIsReSentInvitation: false
      }
    case USERS_ACTIONS.USERS_ACTIONS_RECEIVE_RESPONSE_RESENT_INVITATION:
      return {
        ...state,
        responseUserReSentInvitation: action.response,
        userIsReSentInvitation: true,
        userIsReSentInvitationLoading: false
      }
    case USERS_ACTIONS.USERS_ACTIONS_UPDATE_USER_ACTIVE: {
      const aux = { ...state.updateInfoUserUI }
      aux[action.id] = action.value
      return {
        ...state,
        updateInfoUserUI: aux
      }

    }
    case USERS_ACTIONS.USERS_ACTIONS_GO_TO_PAGE: {
      console.log(action)
      console.log(state)
      let calculateNewPage;
      if (action.pageCurrentDashboard > state.pageCurrentDashboard) calculateNewPage = action.pageCurrentDashboard
      if (action.pageCurrentDashboard < state.pageCurrentDashboard) calculateNewPage = state.pageCurrentDashboard - action.pageCurrentDashboard
      console.log(calculateNewPage)
      return {
        ...state,
        isChangedByButtonNumber: true,
        pageCurrentDashboard: calculateNewPage,
        changePageDashboard: true,
        allUserLoaded: true
      }
    }

    case USERS_ACTIONS.USERS_ACTIONS_CHANGE_ORDER: {
      const aux = { ...state.objUsersFiltered }
      if (action.direction !== 'none') {
        aux.sort = action.direction+"_"+action.field;
      } else {
        aux.sort = '';
      }
      const orderBy = {
        field: action.field,
        direction: action.direction
      }

      return {
        ...state,
        orderBy: orderBy,
        changePageDashboard: false,
        responseListAllUsers: null,
        objUsersFiltered: aux
      }
    }

    default:
      return state
  }
}

export default users;
