import { WHITELIST_ACTIONS } from "../../constants/actions";
import { defaultValuesWhitelist } from "../../constants/constants";

let defaultState = defaultValuesWhitelist();

const whitelist = (state = defaultState, action) => {
    switch (action.type) {

        case WHITELIST_ACTIONS.WHITELIST_ACTIONS_FETCH_NEXT_PAGE_WHITELIST_BY_CARD:
            return {
                ...state,
                pageCurrentWhiteList: state.pageCurrentWhiteList + 1,
                responseWhiteListByCard: null,
                isLoadingWhiteListByCard: false,
            }
        case WHITELIST_ACTIONS.WHITELIST_ACTIONS_FETCH_BACK_PAGE_WHITELIST_BY_CARD:
            return {
                ...state,
                pageCurrentWhiteList: state.pageCurrenWhiteList - 1,
                responseWhiteListByCard: null,
                isLoadingWhiteListByCard: false
            }
        case WHITELIST_ACTIONS.WHITELIST_ACTIONS_FETCH_GOTO_PAGE_WHITELIST_BY_CARD:
            return {
                ...state,
                pageCurrentWhiteList: action.page,
                responseWhiteListByCard: null,
                isLoadingWhiteListByCard: false
            }
        case WHITELIST_ACTIONS.WHITELIST_ACTIONS_FETCH_WHITELIST_BY_CARD:
            return {
                ...state,
                detailCardNumber: action.card,
                responseWhiteListByCard: null,
                isLoadingWhiteListByCard: true,
                pageCurrentWhiteList: action.page
            }
        case WHITELIST_ACTIONS.WHITELIST_ACTIONS_RECEIVE_FETCH_WHITELIST_BY_CARD:
            return {
                ...state,
                responseWhiteListByCard: action.response,
                isLoadingWhiteListByCard: false,
                tabListWhiteListByCard: action.response.data
            }
        case WHITELIST_ACTIONS.WHITELIST_ACTIONS_CLEAN_WHITELIST_BY_CARD:
            return {
                ...state,
                responseWhiteListByCard: null,
                isLoadingWhiteListByCard: false
            }
        case WHITELIST_ACTIONS.WHITELIST_ACTIONS_UPDATE_COMMERCE:
            return {
                ...state,
                updatingCommerce: true,
                updatedCommerce: false,
                responseUpdateCommerce: null
            }
        case WHITELIST_ACTIONS.WHITELIST_ACTIONS_RECEIVE_UPDATE_COMMERCE:
            return {
                ...state,
                updatingCommerce: false,
                updatedCommerce: true,
                responseUpdateCommerce: (action.response) ? action.response : 'error'
                
            }
        case WHITELIST_ACTIONS.WHITELIST_ACTIONS_RESET_UPDATE_COMMERCE:
            return {
                ...state,
                updatingCommerce: false,
                updatedCommerce: false,
                responseUpdateCommerce: null,
                responseWhiteListByCard:null,
            }
        
        default:
            return state
    }
}

export default whitelist;