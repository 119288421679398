import React, { useEffect } from 'react';

import Root from './root';
import './App.css';
// import { initGA } from './index';



const App: React.FC = (props) => {
    // useEffect(() => { initGA(); }, []);
    return (
        <Root />
    );
};

export default App;
